export const formatDateShort = (dateStr: string) => {
  const date = new Date(dateStr);

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  }).format(date);

  const [month, day, year] = formattedDate.split(" ");
  return `${month}. ${day} ${year}`;
};

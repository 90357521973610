import React, { useEffect, useState } from "react";
import "./styles.scss";
import CardsMediaLibrary from "../../components/Cards/CardsMediaLibrary";
import PageTitle from "../../components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { ICatalogResponse } from "../../types/catalog";
import getPublicDetails from "../../api/publicDetails/getProfileDetails";

const MediaLibraryPage: React.FC = () => {
  const { setTertiaryScreen, params } = useDeskNavigation();

  const { userId } = params;
  const token = localStorage.getItem("roses-access-token");
  const [producerDetails, setProducerDetails] = useState<ICatalogResponse>({
    displayname: "",
    isFavorite: false,
    isVip: false,
    profile: {
      age: 0,
      gender: "",
      height: 0,
      location: "",
      maritalStatus: "",
      occupation: "",
      photos: [],
      profileDetails: [],
      rating: 0,
      weight: 0,
    },
    userId: "",
  });

  useEffect(() => {
    const producerDetail = async () => {
      if (token) {
        const response = await getPublicDetails(token, userId);

        switch (response?.status) {
          case 200:
            setProducerDetails(response.res);

            break;
          default:
            // setPrincipalScreen("");
            break;
        }
      }
    };
    producerDetail();
  }, [userId]);

  return (
    <>
      <div id="media-library-page">
        <PageTitle
          title="Media Library"
          onBackClick={() => setTertiaryScreen("")}
          className="no-padding"
        />
        <div className="media-library-photos-container">
          {producerDetails.profile.photos.map((card, index) => (
            <CardsMediaLibrary
              key={index}
              image={card.url}
              altImage={card.url}
              rosesPrice={index++}
              unlockClick={() => console.log(index)}
              isBlurred={index % 2 === 0}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default MediaLibraryPage;

import { useModal } from "../../../../hooks/useModal";
import React from "react";
import SlideFooterPreview from "../../../Carousels/SliderFooterPreview";
import CloseIconGray from "../../../../assets/icons/closeIcons/close-icon-gray.svg";
import "../chat-modal-global-style.scss";

interface PackMessageModalProps {
  urlsObj: {
    url: string;
    fileType: string;
    mimetype: string;
  }[];
}

const PackMessageModal: React.FC<PackMessageModalProps> = (props) => {
  const { urlsObj } = props;
  const { setOpenUseModal } = useModal();

  return (
    <>
      <img
        src={CloseIconGray}
        alt="close icon slide"
        onClick={() => setOpenUseModal(false)}
      />

      <SlideFooterPreview
        fileMaxWidth={600}
        hasArrows={false}
        slides={urlsObj.map((file) => {
          return {
            fileType: file.fileType === "VIDEO" ? "video" : "image",
            src: file.url,
          };
        })}
      />
    </>
  );
};

export default PackMessageModal;

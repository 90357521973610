import { useModal } from "../../../../hooks/useModal";
import React, { useEffect, useState } from "react";
import CloseIconGray from "../../../../assets/icons/closeIcons/close-icon-gray.svg";
import "../chat-modal-global-style.scss";
import ChatTextarea from "../../../TextAreas/ChatTextArea";
import { thumbnailGenerator } from "../../../../utils/thumbnailGenerator";

interface PreviewMediaModalProps {
  images: (string | ArrayBuffer)[];
  fileType: "video" | "image";
  uploadFileCallBack: (
    file: File[],
    content: string,
    thumbnail: string
  ) => Promise<void>;
  files: File[];
}

const PreviewMediaModal: React.FC<PreviewMediaModalProps> = (props) => {
  const { images, uploadFileCallBack, files } = props;
  const [thumbnail, setThumbnail] = useState("");

  const { setOpenUseModal } = useModal();

  // useEffect(() => {
  //   thumbnailGenerator(image)
  //     .then((thumbnail: any) => {
  //       setThumbnail(thumbnail);
  //     })
  //     .catch((error: any) => {
  //       console.error("Erro ao gerar a thumbnail:", error);
  //     });
  // }, [images]);

  return (
    <div className="modal-preview-file">
      <div className="header-modal-preview">
        <img
          src={CloseIconGray}
          alt="close icon"
          onClick={() => setOpenUseModal(false)}
        />
      </div>

      {images.map((imageSrc, index) => (
        <div key={index} className="preview-file-container">
          <img
            src={imageSrc as string}
            alt={`Preview ${index}`}
            className="file-modal-content"
          />
        </div>
      ))}

      <ChatTextarea
        autoFocus
        hasEmptyMessage
        onSendMessage={(e) => uploadFileCallBack(files, e, thumbnail)}
        className="modal-preview-file__textarea"
      />
    </div>
  );
};

export default PreviewMediaModal;

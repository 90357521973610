import { useEffect } from "react";
import FooterLP from "../../../components/Footers/FooterLP";
import NavBar from "../../../components/Navbars/NavBar";

import { useLanguage } from "../../../hooks/languageContext";
import { translateTextNodes } from "../../../utils/translation";

import "./styles.scss";

const TermsPage: React.FC = () => {
	const { translations } = useLanguage();

	useEffect(() => {
    translateTextNodes(translations);
  }, [translations]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavBar />

      <section id="terms" className="terms-wrapper wrapper padding-top padding-hor-24">
        <div className="terms-wrapper-inner inner">
          <h1 textkey="9/termsTitle">
            <strong>Terms</strong> of Service
          </h1>

          <hr />

          <ol>
            <li textkey="9/termsList01">
              <a href="#general">General</a>
            </li>
            <li textkey="9/termsList02">
              <a href="#introduction">Introduction</a>
            </li>
            <li textkey="9/termsList03">
              <a href="#acceptance">Acceptance of Term of Use Agreement</a>
            </li>
            <li textkey="9/termsList04">
              <a href="#code_of_conduct">User's Code of Conduct</a>
            </li>
            <li textkey="9/termsList05">
              <a href="#credit_card_payment">Credit Card Payment</a>
            </li>
            <li textkey="9/termsList06">
              <a href="#report_human_trafficking">Report Human Trafficking</a>
            </li>
            <li textkey="9/termsList07">
              <a href="#disclaimer">Disclaimer</a>
            </li>
            <li textkey="9/termsList08">
              <a href="#limitation_of_liability">Limitation of Liability</a>
            </li>
            <li textkey="9/termsList09">
              <a href="#compliance_statement">
                18 U.S.C. 2257 Compliance Statement
              </a>
            </li>
            <li textkey="9/termsList10">
              <a href="#trademark">Trademark</a>
            </li>
            <li textkey="9/termsList11">
              <a href="#jurisdiction">Jurisdiction</a>
            </li>
          </ol>

          <hr />

          <h4 id="general" textkey="9/termsTitle01">
            1. General
          </h4>

          <p className="small-p" textkey="9/termsContent01_01">
            The ownership and legal establishment of RosesElite.com are under
            the purview of Young Lands LLC, a duly formed limited liability
            company in compliance with the legislative statutes of Delaware,
            United States. The company's legally registered office is situated
            at 600 N Broad Street, Suite 5 #3977, Middletown, DE, 19709.
          </p>

          <p className="small-p" textkey="9/termsContent01_02">
            We earnestly implore you to peruse and fully comprehend the terms
            and conditions set forth herein prior to your engagement with this
            website. Your continued use of RosesElite.com signifies an
            unambiguous understanding and unconditional acceptance of these
            terms. Should you find yourself in disagreement with any part of
            these terms, or if their significance is not clear to you, we advise
            refraining from using this site. Be aware that these terms and
            conditions are uniformly applicable across all pages of
            RosesElite.com.
          </p>

          <h4 id="introduction" textkey="9/termsTitle02">
            2. Introduction
          </h4>

          <p className="small-p" textkey="9/termsContent02_01">
            RosesElite.com operates as an exclusive platform, specifically
            created to facilitate the registration and reservation of a
            discounted rate for an innovative application set to launch later in
            the year. It is crucial to understand that RosesElite.com does not
            function as an escort agency. Instead, it serves as a sophisticated
            advertising portal, catering to the needs of both escort agencies
            and independent models. Your access to or use of the Site in any
            form, including but not limited to visiting, browsing, or
            contributing content or materials, explicitly implies your agreement
            to comply with these Terms and Conditions. Terms capitalized within
            this Agreement are defined herein for clarity and specificity.
          </p>

          <h4 id="acceptance" textkey="9/termsTitle03">
            3. Acceptance of Term of Use Agreement
          </h4>

          <p className="small-p" textkey="9/termsContent03_01">
            Upon creating an account or utilizing any service provided by
            RosesElite.com, including but not limited to access via mobile
            devices, mobile applications, or computers (herein collectively
            referred to as the “Service”), you unequivocally agree to adhere to
            the stipulations outlined in these Terms of Use and our Privacy
            Policy. These documents are incorporated by reference into this
            Agreement. This also extends to any terms presented to you in
            connection with the purchase of additional features, products, or
            services offered on the Service (collectively, this “Agreement”).
            Should you disagree with any part of this Agreement, it is advised
            not to access or use the Service.
          </p>

          <p className="small-p" textkey="9/termsContent03_02">
            By using RosesElite.com, you affirm and warrant that you are at
            least 18 years of age (or of legal majority in your jurisdiction),
            possess the legal right to access adult material in your community,
            and will prevent access to minors for any content found herein. You
            acknowledge and agree that your use of this website is compliant
            with the laws applicable to you, and that you are not restricted
            from using this website due to any legal limitations, including age
            restrictions.
          </p>

          <p className="small-p" textkey="9/termsContent03_03">
            You acknowledge your awareness that RosesElite.com is an adult
            website that may feature sexually explicit material, including
            photographs, videos, audio, language, and other adult content. You
            confirm that accessing adult material is not illegal in your
            community or current location, and that you understand and will
            adhere to the standards and laws related to accessing and using such
            material. You accept sole responsibility for your actions in using
            this site.
          </p>

          <p className="small-p" textkey="9/termsContent03_04">
            You agree to indemnify RosesElite.com, its owners, and moderators
            from any liability that may arise from your use of the site. You
            commit not to engage in unauthorized use of materials from this
            site, including downloading, copying, modifying, distributing, or
            creating derivative works without the explicit consent of the
            rightful owners.
          </p>

          <p className="small-p" textkey="9/termsContent03_05">
            Any attempt to circumvent the security measures of RosesElite.com is
            strictly prohibited. Accessing the site implies implicit acceptance
            of these terms. RosesElite.com reserves the right to amend these
            terms and conditions at any time. You are advised to review these
            Terms & Conditions regularly as they are subject to change and are
            binding upon you.
          </p>

          <p className="small-p" textkey="9/termsContent03_06">
            By continuing to use RosesElite.com, you affirm that you have read,
            understood, and agreed to all the terms and conditions stated above.
          </p>

          <h4 id="code_of_conduct" textkey="9/termsTitle04">
            4. User's Code of Conduct
          </h4>

          <p className="small-p" textkey="9/termsContent04_01">
            In utilizing RosesElite.com, you hereby affirm, warrant, and commit
            to adhering to the following Code of Conduct. You acknowledge and
            accept that we, at our sole discretion, may review and, if
            necessary, reject or remove any content posted or uploaded by you
            that contravenes these guidelines. Furthermore, we reserve the
            right, though not the obligation, to terminate your access to the
            Websites for any breach of these conditions:
          </p>

          <ul className="list-roman">
            <li textkey="9/termsContentList04_01">
              Zero-Tolerance Policy: RosesElite.com stringently prohibits human
              trafficking, prostitution, and any form of illegal conduct. We
              actively collaborate with law enforcement, pursuant to legal
              processes such as subpoenas, to investigate criminal activities.
              Any advertisements or user activities that defy our zero-tolerance
              policy may lead to immediate referral to law enforcement and
              account termination. You must ensure that your use of the site is
              in full compliance with RosesElite.com's policies as well as
              federal, state, and local laws, and report any violations to the
              appropriate authorities.
            </li>

            <li textkey="9/termsContentList04_02">
              Prohibited Conduct: The Websites must not be used for engaging in
              any illegal activities, harassment, or behavior deemed offensive.
              This includes the prohibition of posting or uploading any
              communications, graphics, videos, or audio content that are
              libelous, slanderous, abusive, defamatory, racist, obscene, or
              offensive in nature.
            </li>

            <li textkey="9/termsContentList04_03">
              Rights Infringement: You are prohibited from using the Websites in
              a manner that infringes upon the privacy rights, property rights,
              or any other civil rights of individuals.
            </li>

            <li textkey="9/termsContentList04_04">
              Respect for Third Party Rights and Legal Compliance: You agree not
              to post messages or utilize the Websites in any way that (i)
              violates, plagiarizes, or infringes upon the rights of third
              parties, including but not limited to copyright and trademark
              laws, privacy, or other personal or proprietary rights, or (ii) is
              fraudulent, unlawful, or in violation of any applicable law.
            </li>
          </ul>

          <h4 id="credit_card_payment" textkey="9/termsTitle05">
            5. Credit Card Payment
          </h4>

          <p className="small-p" textkey="9/termsContent05_01">
            These Terms and Conditions govern all credit card transactions
            conducted on RosesElite.com. When you make a credit card payment to
            RosesElite.com or request Electronic Communications, you are
            agreeing to these terms. RosesElite.com reserves the right to modify
            these terms at any time, with amendments published on the website at
            least 30 days before they take effect.
          </p>

          <p className="small-p" textkey="9/termsContent05_02">
            Entering your credit card details signifies your confirmation that
            you are the authorized cardholder and that the information provided
            (including the account holder's name, account number, billing
            address, etc.) is accurate. You thereby authorize RosesElite.com to
            charge the specified amount to your credit card.
          </p>

          <p className="small-p" textkey="9/termsContent05_03">
            Upon clicking “Submit” for the payment, you consent to receive a
            one-time electronic confirmation of this transaction to the email
            address you have provided.
          </p>

          <h4 id="report_human_trafficking" textkey="9/termsTitle06">
            6. Report Human Trafficking
          </h4>

          <p className="small-p" textkey="9/termsContent06_01">
            RosesElite.com is firmly committed to raising awareness and
            advocating against human trafficking. Our organization adheres to
            best practices in this critical area and is actively engaged in
            advocacy efforts. We maintain a policy of zero tolerance towards
            child pornography and the involvement of minors on our site. In the
            event of becoming aware of any trafficking activities,
            RosesElite.com promptly and enthusiastically cooperates with law
            enforcement and organizations dedicated to fighting human rights
            abuses.
          </p>

          <p className="small-p" textkey="9/termsContent06_02">
            We urge users to report any suspected instances of sexual
            exploitation of minors and/or human trafficking to the relevant
            authorities. Contact details for various organizations in the United
            States, Germany, and the United Kingdom, including national hotlines
            and agencies specializing in these issues, are provided for this
            purpose. It is our collective responsibility to ensure the safety
            and rights of vulnerable individuals are protected.
          </p>

          <p className="small-p" textkey="9/termsContent06_03">
            United States: National Center for Missing & Exploited Children
            (NCMEC) CyberTipline - report child exploitation 24-Hour Hotline:
            1-800-843-5678
          </p>

          <p className="small-p" textkey="9/termsContent06_04">
            Polaris Project - Report Human Trafficking: 888-373-7888 Dept. of
            Health & Human Services: 888-373-7888 Children of the Night:
            800-551-1300 ACE National: 202-220-3019 Homeland Security
            Investigations Tip Line: 866-DHS-2-ICE Dept. of Justice:
            888-428-7581 FBI Office: http://www.fbi.gov/contact-us/field
          </p>

          <p className="small-p" textkey="9/termsContent06_05">
            Germany: YPA Agent - Jugendschutzbeauftragter: Rechtsanwalt Dr.
            Daniel Kötz
          </p>

          <p className="small-p" textkey="9/termsContent06_06">
            United Kingdom - Modern Slavery Helpline: Anyone who is concerned
            that someone may be a victim of trafficking or has information about
            trafficking should report it to their local police on 101 or
            confidentially to the Modern Slavery Helpline on 08000 121 700 or
            Crime stoppers. In an emergency, always call 999.
          </p>

          <h4 id="disclaimer" textkey="9/termsTitle07">
            7. Disclaimer
          </h4>

          <p className="small-p" textkey="9/termsContent07_01">
            RosesElite.com provides its services on an "as is" and "as
            available" basis. Pursuant to applicable law, RosesElite.com issues
            no warranties, whether express, implied, statutory, or otherwise,
            regarding the service and all content therein. This includes but is
            not limited to any implied warranties of satisfactory quality,
            merchantability, fitness for a particular purpose, or
            non-infringement. RosesElite.com does not guarantee that the service
            will be uninterrupted, secure, or error-free, nor does it assert
            that defects or errors in the service will be corrected, or that any
            content or information obtained through the service will be accurate
            or suitable for your purposes.
          </p>

          <p className="small-p" textkey="9/termsContent07_02">
            RosesElite.com does not accept responsibility for content posted,
            sent, or received by you or any other member or third party through
            the service. Any material obtained through the service is done at
            your own discretion and risk.
          </p>

          <p className="small-p" textkey="9/termsContent07_03">
            Furthermore, RosesElite.com disclaims any responsibility for the
            conduct of users, whether on or off the service.
          </p>

          <h4 id="limitation_of_liability" textkey="9/termsTitle08">
            8. Limitation of Liability
          </h4>

          <p className="small-p" textkey="9/termsContent08_01">
            To the fullest extent permitted by applicable law, RosesElite.com,
            along with its affiliates, employees, licensors, and service
            providers, shall not be liable for any indirect, consequential,
            exemplary, incidental, special, punitive, fixed, or enhanced
            damages. This includes, but is not limited to, loss of profits,
            data, use, goodwill, or other intangible losses, resulting from: (i)
            your access to or use of or inability to access or use the service;
            (ii) the conduct or content of any members or third parties on or
            through our websites or in connection with the service; or (iii) any
            unauthorized access, use, or alteration of your content. The
            aggregate liability of RosesElite.com for any claims arising out of
            or relating to the service or this agreement shall not exceed the
            amount paid by you to RosesElite.com, if any, during the twenty-four
            (24) month period immediately preceding the date of the first claim
            filed against RosesElite.com. This limitation of liability applies
            irrespective of the basis of liability (contract, tort, statute, or
            otherwise) and regardless of the type of breach of obligations. The
            limitation of liability provisions set forth in this section shall
            apply even if your remedies under this agreement fail in their
            essential purpose. It should be noted that some jurisdictions do not
            allow the exclusion or limitation of certain damages, so these
            exclusions and limitations may not apply to you.
          </p>

          <h4 id="compliance_statement" textkey="9/termsTitle09">
            9. 18 U.S.C. 2257 Compliance Statement
          </h4>

          <p className="small-p" textkey="9/termsContent09_01">
            RosesElite.com is fully compliant with 18 U.S.C. 2257 and all
            associated regulations. All models, actors, actresses, and other
            persons appearing in any visual depiction of actual sexually
            explicit conduct appearing or otherwise contained in this website
            were over the age of eighteen years at the time of the creation of
            such depictions. Records required to be maintained pursuant to 18
            U.S.C. 2257 are kept by the custodian of records, who can be reached
            at 600 N BROAD STREET SUITE 5 #3977, MIDDLETOWN, DE, 19709.
          </p>

          <h4 id="trademark" textkey="9/termsTitle10">
            10. Trademark
          </h4>

          <p className="small-p" textkey="9/termsContent10_01">
            All trademarks, service marks, and trade names of RosesElite.com
            used on the site are trademarks or registered trademarks of
            RosesElite.com or its affiliates. Other marks, graphics, logos,
            scripts, and sounds are proprietary to RosesElite.com. No trademark
            or service mark license is granted in connection with the materials
            contained on this site. Accessing our website does not authorize
            anyone to use any name, logo, or mark in any manner.
          </p>

          <h4 id="jurisdiction" textkey="9/termsTitle11">
            11. Jurisdiction
          </h4>

          <p className="small-p" textkey="9/termsContent11_01">
            The terms, conditions, and use of the RosesElite.com website shall
            be governed by and construed in accordance with the laws of the
            State of Delaware, without giving effect to any principles of
            conflicts of law. By using this site, you agree that any legal
            action or proceeding between RosesElite.com and you for any purpose
            concerning this Agreement or the parties' obligations hereunder
            shall be brought exclusively in a federal or state court of
            competent jurisdiction sitting in Delaware, USA.
          </p>
        </div>
      </section>
      <FooterLP />
      {/* </LayoutTermsPagesSite> */}
    </>
  );
};

export default TermsPage;
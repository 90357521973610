import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ListItem from "../../components/Lists/ListItem";
import closePeachIcon from "../../assets/icons/closeIcons/close-icon-peach.svg";
import Button from "../../components/Buttons/Button";

const UserSettingsMyContactsPage: React.FC = () => {
  return (
    <div className="user-settings">
      <div className="list-container">
        <PageTitle title={"My Contacts"} subtitle={"Configuration"} />
        <ListItem
          showLabel={true}
          labelContent="André Toti"
          showDescription={true}
          descriptionContent="+55(11)967700452"
          showIconLeft={true}
          iconLeftContent={closePeachIcon}
          showIconRight={true}
          iconRightContent={closePeachIcon}
          showSeparator={true}
        />
        <ListItem
          showLabel={true}
          labelContent="André Toti"
          showDescription={true}
          descriptionContent="+55(11)967700452"
          showIconLeft={true}
          iconLeftContent={closePeachIcon}
          showIconRight={true}
          iconRightContent={closePeachIcon}
          showSeparator={true}
        />
        <ListItem
          showLabel={true}
          labelContent="André Toti"
          showDescription={true}
          descriptionContent="+55(11)967700452"
          showIconLeft={true}
          iconLeftContent={closePeachIcon}
          showIconRight={true}
          iconRightContent={closePeachIcon}
          showSeparator={true}
        />
      </div>

      <Button buttonStyle="primary" onClick={() => {}}>
        Add Contact
      </Button>
    </div>
  );
};

export default UserSettingsMyContactsPage;

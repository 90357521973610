let base_url_dev = process.env.REACT_APP_API_URL;
let base_url_images = process.env.REACT_IMAGES_URL;

const currentURL = base_url_dev;

const servicesAPI = {
  whatsappCode: `${currentURL}/wpp`,
  smsCode: `${currentURL}/sms`,
  membership: `${currentURL}/membership-applications`,
  user: `${currentURL}/user`,
  chat: `${currentURL}/chat`,
  auth: `${currentURL}/auth`,
  contact: `${currentURL}/contact`,
  mvpTests: `${currentURL}/mvp-tests`,
  profileDetails: `${currentURL}/profile-details`,
  ipInfo: `${currentURL}/ipinfo`,
  image: `${base_url_images}`,
  creator_applications: `${currentURL}/creator-applications`,
  coins: `${currentURL}/coins`,
  autoRecharge: `${currentURL}/auto-recharge`,
  extract: `${currentURL}/extract`,
  posts: `${currentURL}/posts`,
  moments: `${currentURL}/moments`,
  notifications: `${currentURL}/notifications`,
} as const;

export type ServiceType = keyof typeof servicesAPI;

export const servicesApi = <T extends ServiceType>(
  service: T
): (typeof servicesAPI)[T] => {
  return servicesAPI[service];
};

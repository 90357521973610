import fetchApi from "../fetchApi";

const getExtract = async (
  token: string,
  userId: string,
  startDate: string,
  endDate: string,
  isCashout?: boolean
) => {
  return await fetchApi({
    service: "extract",
    method: "GET",
    endPoint: `?userId=${userId}&startDate=${startDate}&endDate=${endDate}`,
    token,
  });
};

export default getExtract;

import fetchApi from "../fetchApi";

const postSendRoses = async (
  token: string,
  receiverId: string,
  amount: number,
  message: string
) => {
  return await fetchApi({
    service: "coins",
    endPoint: `/transfer/${receiverId}`,
    method: "POST",
    data: { amount, message },
    token,
  });
};

export default postSendRoses;

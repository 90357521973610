import fetchApi from "../fetchApi";

const postBuyRoses = async (
  token: string,
  packageId: string
) => {
  return await fetchApi({
    service: "coins",
    endPoint: `/buy`,
    method: "POST",
    data: { packageId },
    token,
  });
};

export default postBuyRoses;

import React, { useState } from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import InputText from "../../components/Inputs/InputText";
import { useUserInfo } from "../../hooks/userInfo";
import Button from "../../components/Buttons/Button";

const UserSettingsEditBioPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo } = useUserInfo();
  const [value, setValue] = useState("");

  const saveEditHandler = () => {};

  return (
    <div className="user-settings-edit-profile">
      <PageTitle
        title={"Edit Profile"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/user-settings-profile")}
      />
      <main className="user-settings-edit-profile__main margin-24">
        <div className="user-settings-edit-profile__input-container">
          <InputText
            label="Bio"
            placeholder={"About me"}
            inputType="text"
            onChangeInput={(value) => console.log(value)}
            value={value}
          />
        </div>

        <Button
          className="margin-top-40"
          buttonStyle="primary"
          onClick={saveEditHandler}
        >
          Done
        </Button>
      </main>
    </div>
  );
};

export default UserSettingsEditBioPage;

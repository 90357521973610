import Header from "../../components/Headers/Header";
import "./styles.scss";
import Button from "../../components/Buttons/Button";
import PhotoGallery from "../../components/PhotoGallery";
import { useEffect, useState } from "react";
import ArrowIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import Toast from "../../components/Toast";
import { useUserInfo } from "../../hooks/userInfo";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import { textCapitalize } from "../../utils/textCapitalize";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import patchCreatorApplicationDetails from "../../api/creatorApplications/patchCreatorApplicationDetails";

const UserInformation: React.FC = () => {
  const [editInfo, setEditInfo] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [instagramProfile, setInstagramProfile] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [firstInfo, setFirstInfo] = useState({} as any);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [photos, setPhotos] = useState([] as any[]);
  const [errorMessageToast, setErrorMessageToast] = useState("");
  const [successMessageToast, setSuccessMessageToast] = useState("");
  const [hasSuccess, setHasSuccess] = useState(false);

  const { setUserInfo, userInfo } = useUserInfo();

  const { setPrincipalScreen } = useDeskNavigation();

  useEffect(() => {
    setDisplayName(userInfo.display_name);
    setPhoneNumber(userInfo.user_phone);
    setAccountType(textCapitalize(userInfo.account_type) || "");
    setInstagramProfile(userInfo.instagram);
  }, [
    userInfo.account_type,
    userInfo.display_name,
    userInfo.instagram,
    userInfo.user_phone,
  ]);

  const confirmEditionSubmit = async () => {
    setIsLoading(true);

    const data = {
      displayName,
      instagramUser: instagramProfile,
    };

    const patchInfoResponse = await patchCreatorApplicationDetails(
      userInfo.access_token,
      data
    );

    switch (patchInfoResponse?.status) {
      case 200:
        setUserInfo("instagram", instagramProfile);
        setUserInfo("display_name", displayName);
        setHasSuccess(true);
        setSuccessMessageToast(
          "Your information has been updated successfully!"
        );

        setTimeout(() => {
          setHasSuccess(false);
        }, 4000);

        break;

      default:
        setHasError(true);
        setErrorMessageToast(
          "There was a problem with updating your information. Please try again."
        );
        setTimeout(() => setHasError(false), 4000);
        break;
    }

    setEditInfo(false);
    setIsLoading(false);
  };

  const handleGoBack = () => {
    setPrincipalScreen("/wait-list-application");
  };

  const handleCancel = () => {
    setEditInfo(false);
    setDisplayName(firstInfo.displayName);
    setInstagramProfile(firstInfo.instagramProfile);
  };

  const handleEdit = () => {
    setEditInfo(true);
    setFirstInfo({
      displayName,
      instagramProfile,
    });
  };

  return (
    <div className="user-information margin-hor-24">
      <div>
        <Header
          headerIcon="back-button"
          marginTop={24}
          backButtonClick={handleGoBack}
          title="My information"
          subTitle={`We continually review our waitlist and will send you a push 
					notification if there are any changes to your application status.`}
        />
        <div className="list-container">
          <ListItemSecondary
            showLabel
            showValue={!editInfo}
            labelContent="Display name"
            valueContent={displayName}
            showSeparator
            showInput={editInfo}
            valueInput={displayName}
            onChangeInput={setDisplayName}
            iconRightContent={ArrowIcon}
            className="my-information-list"
          />
          <ListItemSecondary
            showLabel
            showValue
            labelContent="Phone number"
            valueContent={phoneNumber}
            showSeparator
            className="my-information-list"
          />
          <ListItemSecondary
            showLabel
            showValue
            labelContent="Account type"
            valueContent={accountType}
            showSeparator
            className="my-information-list"
          />
          <ListItemSecondary
            showLabel
            showValue={!editInfo}
            labelContent="Instagram"
            valueContent={instagramProfile}
            showSeparator
            showInput={editInfo}
            valueInput={instagramProfile}
            onChangeInput={setInstagramProfile}
            className="my-information-list"
          />
        </div>

        <div className="photo-container">
          <PhotoGallery files={photos} />
        </div>
      </div>

      {editInfo ? (
        <>
          <Button
            buttonStyle="primary"
            onClick={confirmEditionSubmit}
            disabled={isLoading}
          >
            Save
          </Button>
          <Button buttonStyle="tertiary" onClick={handleCancel}>
            Cancel
          </Button>
        </>
      ) : (
        <Button buttonStyle="tertiary" onClick={handleEdit}>
          Edit
        </Button>
      )}

      <Toast
        type="error"
        description={errorMessageToast}
        isVisible={hasError}
        setIsVisible={setHasError}
        title="Error"
      />
      <Toast
        type="success"
        description={successMessageToast}
        isVisible={hasSuccess}
        setIsVisible={setHasSuccess}
        title="Success"
      />
    </div>
  );
};

export default UserInformation;

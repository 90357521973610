import React, { forwardRef, ReactNode } from "react";
import "./styles.scss";

interface IListItemExtract {
  title?: string;
  description?: string;
  amount?: string;
  value?: string;
  date?: {
    long: string;
    short: string;
  };
  isExtract?: boolean;
  iconRight?: string;
  children?: ReactNode;
  className?: string;
}

const ListItemExtract = forwardRef<HTMLDivElement, IListItemExtract>((props, ref) => {
  const {
    title,
    description,
    amount,
    value,
    date,
    isExtract = false,
    iconRight,
    children,
    className,
    ...rest
  } = props;

  const isPositive = (n: string) => Number(n) > 0;

  let valueClear: string | number | undefined = value;
  if (value?.search('$') !== -1) {
    valueClear = value?.replace(/[^0-9.]/g, "");
  }

  return (

    <article className={`item ${className ?? ""}`} ref={ref} {...rest}>
      {(title || date) && (
        <header className="item__top">
          {title && <p data-title className="item__title no-margin">{title}</p>}
          {date && (
            <time data-date className="item__date" dateTime={date?.long}>
              {date?.short}
            </time>
          )}
        </header>
      )}

      {(amount || value != null) && (
        <section className="item__infos">
          {amount && (
            <span
              data-amount
              className={`
                item__info
                ${isExtract && isPositive(amount) ? "item__info--positive" : "item__info--amount"}
              `}>
              {isPositive(amount) ? "+" : "-"} {amount}
            </span>
          )}
          {value != null && (
            <span
              data-value
              className={`
                item__info
                ${isExtract && "item__info--extract"}
                ${isExtract && isPositive(String(valueClear)) ? "item__info--positive" : ""}
              `}>
              {isPositive(String(valueClear)) ? "+" : "-"} {value}
            </span>
          )}

          {iconRight && (
            <div className="item__icon margin-left-16">
              <img src={iconRight} alt="" />
            </div>
          )}
        </section>
      )}

      {children}
    </article>
  )
})

export default ListItemExtract;

import React, { useEffect, useState } from "react";
import "./styles.scss";

interface ButtonIconProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle?:
  | "primary"
  | "secondary"
  | "tertiary"
  | "inverse"
  | "transparent"
  | "transparentActive";
  icon?: string;
  iconClass?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const ButtonIcon: React.FC<ButtonIconProps> = (props) => {
  const { buttonStyle = "primary", icon = "icon-heart-solid", iconClass, className, onClick } = props;

  return (
    <button
      id="button-icon"
      onClick={onClick}
      className={`button-icon button-icon--${buttonStyle} ${className ?? ""}`}
    >
      <span
        className={`icon ${icon} icon-md ${iconClass ?? ""}`}>
      </span>
    </button >
  );
};

export default ButtonIcon;

import fetchApi from "../fetchApi";

const postWithdrawRoses = async (
  token: string,

  amount: number
) => {
  return await fetchApi({
    service: "coins",
    endPoint: `/withdraw`,
    method: "POST",
    data: { amount },
    token,
  });
};

export default postWithdrawRoses;

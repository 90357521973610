import React from "react";
import "./styles.scss";
import GlobeIcon from "../../../assets/icons/globe-gray-icon.svg";

interface CardExplanatoryProps {
  className?: string;
  title: string;
  info: string;
  linkText: string;
  linkRoute: string;
}

const CardExplanatory: React.FC<CardExplanatoryProps> = (props) => {
  const { className, info, title, linkText, linkRoute } = props;

  return (
    <section id="card-explanatory" className={className ?? ""}>
      <div className="card-explanatory__icon">
        <img src={GlobeIcon} alt="globe icon" />
      </div>

      <div className="card-explanatory__text-wrapper">
        <p className="text-wrapper__title no-margin">{title}</p>
        <p className="text-wrapper__info no-margin">
          {info}{" "}
          <a className="text-wrapper__link" href={linkRoute}>
            {linkText}
          </a>
        </p>
      </div>
    </section>
  );
};

export default CardExplanatory;

import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ButtonNavigation from "../../components/Buttons/ButtonNavigation";
import InputText from "../../components/Inputs/InputText";
import { useWebSocket } from "../../contexts/webSocketContext";
import { useNavigate } from "react-router-dom";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import getListUserChats from "../../api/chat/getListUserChats";
import { useUserInfo } from "../../hooks/userInfo";
import { Chat } from "../../types/chatInfo";
import profileAvatar from "../../assets/icons/profile-icon-gray.svg";
import { useChat } from "../../contexts/openedChatContext";
import ListItemLink from "../../components/Lists/ListItemLink";
import getNotifications from "../../api/notifications/getNotifications";

const ChatListPage: React.FC = () => {
  const { getChats } = useWebSocket();
  const [activeSection, setActiveSection] = useState<string>("button1");
  const [chats, setChats] = useState<Chat[]>([]);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<{
    [chatId: string]: number;
  }>({});
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();
  const { addChatListener, removeListener } = useWebSocket();
  const { setTertiaryScreen, setSecondaryScreen, setPrincipalScreen } =
    useDeskNavigation();
  const { setActiveChatId } = useChat();
  const [notifications, setNotifications] = useState<any[]>([]);

  useEffect(() => {
    const handleNewMessage = (newMessage: any) => {
      if (newMessage.eventType === "NEW_MESSAGE") {
        const { chatId, isRead } = newMessage.payload;
        setUnreadMessagesCount((prev) => ({
          ...prev,
          [chatId]: !isRead ? (prev[chatId] || 0) + 1 : prev[chatId],
        }));
      }
    };

    addChatListener(handleNewMessage);

    return () => {
      removeListener("events", handleNewMessage);
    };
  }, [addChatListener, removeListener]);

  const handleChat = (chatId: string, userId: string) => {
    setActiveChatId(chatId);
    setTimeout(() => {
      setUnreadMessagesCount((prev) => ({
        ...prev,
        [chatId]: 0,
      }));

      setSecondaryScreen("/personal-chat", { chatId, userId });
    }, 500);
  };

  const messageInChatRequest = async () => {
    const response = await getListUserChats(userInfo.access_token);

    if (response.status === 200) {
    } else {
      navigate("/login");
    }
  };

  const getListChats = useCallback(() => {
    getChats({}, (res) => {
      setChats(res);
    });
  }, [getChats]);

  useEffect(() => {
    messageInChatRequest();
    getListChats();
  }, [getListChats]);

  const notificationsMock = [
    {
      title: "Mr. Grey",
      content: "Unlocked chat",
      type: "PAID_CONTENT",
    },
    {
      title: "Dream Man",
      content: "Sent you roses credit",
      type: "RECEIVED_ROSES",
    },
    {
      title: "Nick",
      content: "Added you to their favorites",
      type: "FAVORITED",
    },
    {
      title: "Aron",
      content: "Sent you a new message message",
      type: "SYSTEM",
    },
  ]

  const getListNotifications = useCallback(async () => {
    const request = await getNotifications(
      userInfo.access_token,
    )

    if (request.status === 200) {
      setNotifications(request.res);
    } else {
      setNotifications(notificationsMock);
    }
  }, []);

  // PAID_CONTENT
  // RECEIVED_ROSES
  // FAVORITED
  // SYSTEM

  useEffect(() => {
    getListNotifications();
  }, [getListNotifications]);

  const messagePreview = (message: string) => {
    return message?.length > 50 ? `${message.substring(0, 40)}...` : message;
  };

  const backPageHandler = () => {
    setPrincipalScreen("");
    setTertiaryScreen("");
    setSecondaryScreen("");
    setActiveChatId(null);
  };

  const [searchValue, setSearchValue] = useState("");

  return (
    <div className="chat-list margin-hor-24">
      <div className="top-container">
        <PageTitle
          title={"Messages"}
          subtitle={"Conversation"}
          onBackClick={backPageHandler}
        />
        <InputText
          value={searchValue}
          placeholder="Search Messages"
          onChangeInput={(e) => { setSearchValue(e) }}
          searchInput
          inputType="text"
        />
        <ButtonNavigation
          setActiveSection={setActiveSection}
          activeSection={activeSection}
          buttonCount={2}
          buttonLabels={["Messages", "Notifications"]}
          className="margin-vert-24"
        />
      </div>

      {activeSection === "button1" && (
        <>
          {chats.map((chat, index, arr) => (
            <ListItemLink
              iconFill
              key={chat.chatId}
              highlightText={chat.chatParticipants[0]?.user.displayname}
              hasArrowRight
              isImageCircle
              simpleText={messagePreview(chat.messages[0]?.content) || ""}
              hasSeparator={arr.length > index + 1}
              imageLeft={
                chat.chatParticipants[0]?.user.profile?.photos[0]?.url
                  ? chat.chatParticipants[0]?.user.profile?.photos[0]?.url
                  : profileAvatar
              }
              onClick={() =>
                handleChat(chat.chatId, chat.chatParticipants[0]?.user.userId)
              }
              rightNodeContent={
                <div
                  className="notification-count"
                  style={{
                    display:
                      chat._count.messages +
                        (unreadMessagesCount[chat.chatId] || 0) ===
                        0
                        ? "none"
                        : "flex",
                  }}
                >
                  <p className="notification-count__value">
                    {chat._count.messages +
                      (unreadMessagesCount[chat.chatId] || 0)}
                  </p>
                </div>
              }
            />
          ))}
        </>
      )}

      {activeSection === "button2" && <>
        {notifications.length > 0 ?
          <>
            {notifications.map(notification => {
              return <ListItemLink
                imageLeft=""
                highlightText={notification.title}
                simpleText={notification.content}
                hasArrowRight
                onClick={function (a: any): void {
                  console.error("Function not implemented.");
                }} />
            })}
          </>
          :
          <p>
            You don't have any notifications yet!
          </p>
        }
      </>}
    </div>
  );
};

export default ChatListPage;

import fetchApi from "../fetchApi";

const ChangePassword = async (data: { oldPassword: string; newPassword: string }) => {
  return await fetchApi({
    service: "auth",
    endPoint: "/change-password",
    method: "POST",
    data,
  });
};

export default ChangePassword;

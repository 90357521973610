import React, { useEffect, useState } from "react";
import "./styles.scss";
import Header from "../../components/Headers/Header";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import Toast from "../../components/Toast";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import InputText from "../../components/Inputs/InputText";
import { IToast } from "../../types/toast";
import googleIcon from "../../assets/icons/buttonIcons/google-icon-gray.svg";
import postUserAuth from "../../api/auth/postLogin";
import * as jwtDecode from "jwt-decode";
import { IInfoToken } from "../../types/infoToken";
import getCreatorStatus from "../../api/creatorApplications/getCreatorStatus";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../components/LoadingPage";
import getAuthGoogle from "../../api/auth/getAuthGoogle";

const AccountLoginPage: React.FC = () => {
  //STATES

  const [emailInputWarning, setEmailInputWarning] = useState(false);
  const [passwordInputWarning, setPasswordInputWarning] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, setUserInfo } = useUserInfo();

  //HOOKS
  const navigate = useNavigate();
  const { setComponent } = useLoginSignUpNavigation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const error = params.get("error");
    const code = params.get("code");

    if (error) {
      if (code === "409") {
        const newUrl = window.location.origin + window.location.pathname;
        window.history.replaceState(null, "", newUrl);
        setToastShow(true);

        setToastConfig({
          description: "This account is not linked to Google",
          title: "Oops...",
          type: "info",
        });

        setTimeout(() => {
          setToastShow(false);
        }, 5000);
      }
    } else if (token) {
      setComponent(<LoadingPage />);
      tokenTreatment(200, token);
    }
  }, [window.location.search]);

  const loginSubmit = async (
    method: "interAuth" | "oauthGoogle" | "authFingerPrint"
  ) => {
    if (method === "oauthGoogle") {
      const responseAuth = getAuthGoogle();
    } else if (method === "interAuth") {
      if (!userEmail && userPassword) {
        setEmailInputWarning(true);
        setToastConfig({
          description: "You need to add your email to continue",
          title: "Email is empty",
          type: "info",
        });

        setToastShow(true);

        setTimeout(() => {
          setEmailInputWarning(false);
          setToastShow(false);
        }, 2000);

        return;
      }

      if (!userPassword && userEmail) {
        setPasswordInputWarning(true);

        setToastConfig({
          description: "You need to add your password to continue",
          title: "Password is empty",
          type: "info",
        });

        setToastShow(true);

        setTimeout(() => {
          setPasswordInputWarning(false);
          setToastShow(false);
        }, 4000);

        return;
      }

      if (!userPassword && !userEmail) {
        setPasswordInputWarning(true);

        setToastConfig({
          description: "You need to add your password to continue",
          title: "Password is empty",
          type: "info",
        });

        setToastShow(true);

        setTimeout(() => {
          setPasswordInputWarning(false);
          setToastShow(false);
        }, 4000);

        setEmailInputWarning(true);
        setToastConfig({
          description: "You need to add your email to continue",
          title: "Email is empty",
          type: "info",
        });

        setToastShow(true);

        setTimeout(() => {
          setEmailInputWarning(false);
          setToastShow(false);
        }, 2000);

        return;
      }

      const prepareData = {
        email: userEmail,
        password: userPassword,
      };

      const responseAuth = await postUserAuth(prepareData);
      const access_token = responseAuth.res.access_token;
      setComponent(<LoadingPage />);
      setIsLoading(true);

      tokenTreatment(responseAuth?.status, access_token);
    }
  };

  const tokenTreatment = async (status: number | null, token: string) => {
    switch (status) {
      case 200:
        const userInfoToken = (await jwtDecode.jwtDecode(token)) as IInfoToken;

        setUserInfo("access_token", token);
        setUserInfo("display_name", userInfoToken?.validUser.displayname);
        setUserInfo("account_type", userInfoToken?.validUser.role);
        setUserInfo("user_phone", userInfoToken?.validUser.phone);
        setUserInfo("user_email", userInfoToken?.validUser.email);
        setUserInfo("user_id", userInfoToken?.validUser.userId);

        const responseStatus = await getCreatorStatus(token);

        if (responseStatus?.status === 200) {
          setUserInfo("referral_code", responseStatus?.res.referralCode);
          setUserInfo("creator_application_status", responseStatus?.res.status);
          setUserInfo(
            "creator_application_id",
            responseStatus?.res.creatorApplicationId
          );
        }

        if (
          responseStatus?.res?.status === "APPROVED" &&
          userInfoToken?.validUser.role.toLocaleLowerCase() === "consumer"
        ) {
          setComponent("/welcome-user");
        } else {
          navigate("/app");
        }

        break;
      case 401:
        setComponent(<AccountLoginPage />);
        alertToast(
          "Check your credentials and, please, try again",
          "Error",
          "error"
        );

        setPasswordInputWarning(true);
        setEmailInputWarning(true);
        setTimeout(() => {
          setPasswordInputWarning(false);
          setEmailInputWarning(false);
        }, 4000);

        break;

      default:
        alertToast(
          "We had a problem making the request. Please try again",
          "Sorry",
          "error"
        );
        break;
    }
    setIsLoading(false);
  };

  /*     const validateEmail = (email: string): boolean => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

    const recoveryEmailSubmit = () => {
    if (validateEmail(userEmail)) {
    } else {
      setToastConfig({
        type: "error",
        title: "invalid email",
        description: "Add a valid email",
      });

      setToastShow(true);
      setEmailInputWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setEmailInputWarning(false);
      }, 3000);
    }
  }; */

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      loginSubmit("interAuth");
    }
  };

  function alertToast(description: string, title: string, type: any) {
    setToastConfig({
      description: description,
      title: title,
      type: type,
    });

    setToastShow(true);

    setTimeout(() => {
      setToastShow(false);
    }, 4000);
  }

  /*   const loading = () => {
    if (isLoading) {
      <Loading />;
    }
  }; */

  return (
    <div className="account-login-page">
      <div>
        <Header
          title="Welcome!"
          titleClass="header"
          headerIcon="roses-logo"
          subTitle="For your security, please log in to continue accessing our platform."
        />{" "}
        <div className="login-container margin-top-24">
          <InputText
            value={userEmail.toLowerCase()}
            inputType="email"
            placeholder="Email"
            textTransform="none"
            onChangeInput={(e) => setUserEmail(e)}
            isWarning={emailInputWarning}
            className="no-margin"
          />
          <InputText
            inputType="password"
            value={userPassword}
            placeholder="Password"
            onChangeInput={(e) => setUserPassword(e)}
            onKeyDown={onKeyDown}
            isWarning={passwordInputWarning}
            className="no-margin"
          />
          <Button
            disabled={isLoading}
            buttonStyle="tertiary"
            onClick={() => loginSubmit("interAuth")}
          >
            Login
          </Button>

          <div className="oauth-buttons">
            {/* <Button
              disabled={hasError || isLoading}
              buttonStyle="iconButton"
              onClick={loginSubmit}
            >
              <img src={twitterIcon} alt="twitter icon" />
            </Button> */}
            <Button
              onClick={() => loginSubmit("oauthGoogle")}
              buttonStyle="iconButton"
            >
              <img src={googleIcon} alt="google icon" />
            </Button>
            {/* <Button
              disabled={hasError || isLoading}
              buttonStyle="iconButton"
              onClick={()=>loginSubmit("authFingerPrint")}
            >
              <img src={digitalIcon} alt="finger print icon" />
            </Button> */}
          </div>
        </div>
      </div>
      <div className="buttons-container margin-top-24">
        <Button
          disabled={isLoading}
          buttonStyle="quaternary"
          onClick={() => setComponent("/reset-password")}
        >
          Forgot Password
        </Button>
        <Button
          disabled={isLoading}
          buttonStyle="primary"
          onClick={() => setComponent("/create-account")}
        >
          Create account
        </Button>
        <SubText>
          By tapping any of the actions above, you acknowledge that you are over
          the age of 18 and agree to our Terms of Services. Learn how we process
          your data in our Privacy Policy.
        </SubText>
      </div>
      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default AccountLoginPage;

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ProviderProps } from "../types/interfaces";
import { getElementByPath } from "../utils/navigationDesk";

interface Params {
  [key: string]: string;
}

interface DeskNavigationContextType {
  setPrincipalScreen: (path: string | ReactNode, params?: Params) => void;
  setSecondaryScreen: (path: string | ReactNode, params?: Params) => void;
  setTertiaryScreen: (path: string | ReactNode, params?: Params) => void;
  setMainScreen: (path: string | ReactNode, params?: Params) => void;
  componentPrincipal: ReactNode;
  componentSecondary: ReactNode;
  componentTertiary: ReactNode;
  mainScreen: ReactNode;
  params: Params;
}

const DeskNavigationContext = createContext({} as DeskNavigationContextType);

const DeskNavigationProvider = ({ children }: ProviderProps) => {
  const [principalScreen, setPrincipalScreenState] = useState<
    string | ReactNode
  >("");
  const [componentPrincipal, setComponentPrincipal] =
    useState<ReactNode | null>(null);

  const [secondaryScreen, setSecondaryScreenState] = useState<
    string | ReactNode
  >("");
  const [componentSecondary, setComponentSecondary] =
    useState<ReactNode | null>(null);

  const [tertiaryScreen, setTertiaryScreenState] = useState<string | ReactNode>(
    ""
  );
  const [componentTertiary, setComponentTertiary] = useState<ReactNode | null>(
    null
  );

  const [mainScreen, setMainScreenState] = useState<string | ReactNode>("");
  const [componentMain, setComponentMain] = useState<ReactNode | null>(null);

  const [params, setParams] = useState<Params>({});

  const setScreen = (
    setState: React.Dispatch<React.SetStateAction<string | ReactNode>>,
    path: string | ReactNode,
    params?: Params
  ) => {
    if (params) {
      setParams((prev) => ({
        ...prev,
        ...params,
      }));
    }
    setState(path);
  };

  useEffect(() => {
    let page;
    if (typeof principalScreen === "string") {
      page = getElementByPath(principalScreen);
    } else {
      page = principalScreen;
    }

    if (page) {
      setComponentPrincipal(page);
    } else {
      setComponentPrincipal(null);
    }
  }, [principalScreen, setPrincipalScreenState]);

  useEffect(() => {
    let page;
    if (typeof secondaryScreen === "string") {
      page = getElementByPath(secondaryScreen);
    } else {
      page = secondaryScreen;
    }

    if (page) {
      setComponentSecondary(page);
    } else {
      setComponentSecondary(null);
    }
  }, [secondaryScreen, setSecondaryScreenState]);

  useEffect(() => {
    let page;
    if (typeof tertiaryScreen === "string") {
      page = getElementByPath(tertiaryScreen);
    } else {
      page = tertiaryScreen;
    }
    if (page) {
      setComponentTertiary(page);
    } else {
      setComponentTertiary(null);
    }
  }, [tertiaryScreen, setTertiaryScreenState]);

  useEffect(() => {
    let page;
    if (typeof mainScreen === "string") {
      page = getElementByPath(mainScreen);
    } else {
      page = mainScreen;
    }
    if (page) {
      setComponentMain(page);
    } else {
      setComponentMain(null);
    }
  }, [mainScreen, setMainScreenState]);


  return (
    <DeskNavigationContext.Provider
      value={{
        componentPrincipal,
        componentSecondary,
        componentTertiary,
        setPrincipalScreen: (path, params) =>
          setScreen(setPrincipalScreenState, path, params),
        setSecondaryScreen: (path, params) =>
          setScreen(setSecondaryScreenState, path, params),
        setTertiaryScreen: (path, params) =>
          setScreen(setTertiaryScreenState, path, params),
        setMainScreen: (path, params) =>
          setScreen(setMainScreenState, path, params),
        mainScreen: componentMain,
        params,
      }}
    >
      {children}
    </DeskNavigationContext.Provider>
  );
};

const useDeskNavigation = () => {
  const context = useContext(DeskNavigationContext);
  return context;
};

export { DeskNavigationProvider, useDeskNavigation };

import React, { ReactNode } from "react";
import "./styles.scss";

interface ParagraphPros {
  children: ReactNode;
}

const Paragraph: React.FC<ParagraphPros> = ({ children }) => {
  return <p id="paragraph-text">{children}</p>;
};

export default Paragraph;

import { useState } from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ListItem from "../../components/Lists/ListItem";
import ShieldIconGray from "../../assets/icons/shieldIcons/personal-icon-gray.svg";
import Button from "../../components/Buttons/Button";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import SubText from "../../components/Texts/SubText";
import CloseIconPeach from "../../assets/icons/closeIcons/close-icon-peach.svg";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import ListItemToggle from "../../components/Lists/ListItemToggle";

const UserSettingsPrivacyPage: React.FC = () => {
  const [isProfileHidden, setIsProfileHidden] = useState(false);
  const [isBlockRequests, setIsBlockRequests] = useState(false);

  const { setPrincipalScreen } = useDeskNavigation();

  const locationMock = [
    {
      country: "Italy",
      city: "Florence",
    },
    {
      country: "Italy",
      city: "Rome",
    },
  ];

  const handleToggleProfileHidden = (newState: boolean) => {
    setIsProfileHidden(newState);
  };

  const handleToggleBlockRequests = (newState: boolean) => {
    setIsBlockRequests(newState);
  };

  const activateLocationShieldHandler = () => {};
  const hideSpecificLocationHandler = () => {};
  const addLocationHandler = () => {};

  return (
    <div className="user-settings-privacy ">
      <PageTitle
        title={"Account Privacy"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/user-settings-account")}
        className="padding-hor-24"
      />
      <div className="list-container margin-hor-24">
        <SubText fontSize="14px">
          Emergency Assistance lets you choose contacts for emergencies.
          Contacts are notified only if an emergency occurs, receiving your last
          location and activity details for prompt help.
        </SubText>

        <ListItemToggle
          toggleCallBack={activateLocationShieldHandler}
          hasSeparator
          imageLeft={ShieldIconGray}
          isImageCircle
          toggleInitialState
          simpleText="Profile will only show to specific users"
          highlightText="Activate Location Shield"
        />

        <ListItemToggle
          toggleCallBack={hideSpecificLocationHandler}
          hasSeparator
          highlightText="Hide from everyone"
          simpleText="Only show profile to users with a direct link"
        />

        <ListItemToggle
          toggleCallBack={hideSpecificLocationHandler}
          hasSeparator
          highlightText="Hide specific location"
          simpleText={`Your profile will be hidden from users in these 
						locations`}
        />

        <div className="location-list-item">
          {locationMock.map((location, index, arr) => (
            <ListItemSecondary
              key={index}
              showLabel={true}
              labelContent={location.city + ", " + location.country}
              showIconRight
              iconRightContent={CloseIconPeach}
              showSeparator={arr.length > index + 1}
              className="location-list-item-location"
            />
          ))}
        </div>

        <Button
          buttonStyle="tertiary"
          onClick={addLocationHandler}
          className="margin-top-24"
        >
          Add location
        </Button>

        {/* 
        <ListItem
          showLabel={true}
          labelContent="Hide Profile"
          showDescription={true}
          descriptionContent="Profile will not show to others"
          showIconLeft={true}
          iconLeftContent={hideGrayIcon}
          showIconBorderLeft={true}
          showToggle
          onToggleChange={handleToggleProfileHidden}
          showSeparator={true}
        />
        {isProfileHidden && (
          <div className="is-profile-hidden">
            <ListItem
              showLabel={true}
              labelContent="Block Everyone"
              showDescription={true}
              descriptionContent="Profile will not show to others"
              showRadioRight
              showSeparator={true}
            />
            <ListItem
              showLabel={true}
              labelContent="Block Location"
              showDescription={true}
              descriptionContent="Profile will not show to others"
              showRadioRight
            />
            <Button
              children={"Add location"}
              buttonStyle="tertiary"
              onClick={() => {}}
            />
          </div>
        )} */}
        {/* <ListItem
          showLabel={true}
          labelContent="Block Requests"
          showDescription={true}
          descriptionContent="Prohibit rose requests"
          showIconLeft={true}
          iconLeftContent={blockGrayIcon}
          showIconBorderLeft={true}
          showToggle
          onToggleChange={handleToggleBlockRequests}
        /> */}
      </div>
    </div>
  );
};

export default UserSettingsPrivacyPage;

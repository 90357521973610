import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ListItem from "../../components/Lists/ListItem";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import identityIconGray from "../../assets/icons/identity-icon-gray.svg";
import languageIcon from "../../assets/icons/language-icon-gray.svg";
import lockIcon from "../../assets/icons/lock-icon-gray.svg";
import personalSafetyIconGray from "../../assets/icons/shieldIcons/personal-icon-gray.svg";
import gearIcon from "../../assets/icons/gear-icon-gray.svg";
import Button from "../../components/Buttons/Button";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useNavigate } from "react-router-dom";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import ListItemLink from "../../components/Lists/ListItemLink";

const UserSettingsAccountPage: React.FC = () => {
  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const { setComponent, component } = useLoginSignUpNavigation();
  const navigate = useNavigate();

  const logoutHandler = () => {
    sessionStorage.clear();
    localStorage.clear();
    setPrincipalScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setComponent("");
    navigate("/");
  };

  const listItems = [
    {
      labelContent: "Identity Verification",
      iconLeftContent: identityIconGray,
      clickAction: () => {},
    },
    {
      labelContent: "Language & Location",
      iconLeftContent: languageIcon,
      clickAction: () => setPrincipalScreen("/user-settings-language"),
    },
    {
      labelContent: "Password & Security",
      iconLeftContent: personalSafetyIconGray,
      clickAction: () => setPrincipalScreen("/user-settings-change-password"),
    },
    {
      labelContent: "Account Privacy",
      iconLeftContent: lockIcon,
      clickAction: () => setPrincipalScreen("/user-settings-privacy"),
    },
    {
      labelContent: "Manage Account",
      iconLeftContent: gearIcon,
      clickAction: () => setPrincipalScreen("/user-settings-manage-account"),
    },
  ];

  return (
    <div className="user-settings-account">
      <PageTitle
        title={"Account"}
        subtitle={"Manage Account"}
        onBackClick={() => setPrincipalScreen("/user-settings")}
        hasCloseIcon={false}
        className="padding-hor-24"
      />
      <div className="list-container-user-settings margin-hor-24">
        {listItems.map((item, index, arr) => (
          <ListItemLink
            key={index}
            onClick={item.clickAction}
            hasArrowRight
            hasSeparator={arr.length > index + 1}
            highlightText={item.labelContent}
            isImageCircle
            imageLeft={item.iconLeftContent}
          />
        ))}

        <div className="button-bottom">
          <Button
            buttonStyle="secondary"
            onClick={logoutHandler}
            className="margin-top-24"
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserSettingsAccountPage;

import React from "react";
import Verify from "../../../assets/icons/verify.svg";
import "./styles.scss";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import Button from "../../Buttons/Button";
import ButtonIcon from "../../Buttons/ButtonIcon";
import Rating from "../../Rating";
import ChevronDown from "../../../assets/icons/navigationIcons/chevron-down-white.svg";
import twitterIcon from "../../../assets/icons/buttonIcons/twitter-icon-gray.svg";

import photoDefault from "../../../assets/images/roses-photo-default.png";
//import photoDefault from "../../../assets/images/testing/test-profile.png";
import photo1 from "../../../assets/images/testing/teste1.png";
import photo2 from "../../../assets/images/testing/teste2.jpeg";
import photo3 from "../../../assets/images/testing/teste3.png";
import photo4 from "../../../assets/images/testing/teste4.png";
import photo5 from "../../../assets/images/testing/teste5.png";
import photo6 from "../../../assets/images/testing/teste6.png";
import photo7 from "../../../assets/images/testing/teste7.jpeg";
import photo8 from "../../../assets/images/testing/teste8.png";
import Category from "../../Category";
import ProfileDropDown from "./ProfileDropdown";

const userInterests = [
  "Sports",
  "Dancing",
  "White",
  "Fashion",
  "Travel",
  "Adventures",
];

const essentialCards = [
  { icon: "icon-gender", gender: "Gender", info: "Female" },
  { icon: "icon-earth", gender: "Nationality", info: "Brazilian" },
  { icon: "icon-race", gender: "Race", info: "White, Latin" },
  {
    icon: "icon-ethnicity",
    gender: "Ethnicity",
    info: "Italian, Portuguese",
  },
  { icon: "icon-measurement", gender: "Height", info: "5’4” / 1,67m" },
  { icon: "icon-gym", gender: "Body Style", info: "Slim, Athletic" },
  { icon: "icon-zodiac", gender: "Zodiac", info: "Sagittarius" },
  {
    icon: "icon-language",
    gender: "Languages",
    info: "Portuguese",
  },
];

interface ProfileDetailsProps {
  isVip: boolean;
  principalPhoto: string;
  rating: number;
  isVerified: boolean;
  name: string;
  age: string | number;
  profileDetails: string[];
  sendMessageClick: (a: any) => void;
  heartButtonClick: (a: any) => any;
  buttonStyle:
    | "primary"
    | "secondary"
    | "tertiary"
    | "inverse"
    | "transparent"
    | "transparentActive";
  icon: string;
}

const NewProfile: React.FC<ProfileDetailsProps> = (props) => {
  const {
    isVip,
    principalPhoto,
    rating,
    name,
    age,
    isVerified,
    profileDetails,
    sendMessageClick,
    heartButtonClick,
    buttonStyle,
    icon,
  } = props;

  return (
    <div id="profile">
      <div className="profile-banner">
        {/* {isVip && (
          <div className="vip-tag">
            <div className="background " />
            <p className="small-p no-margin">VIP</p>
          </div>
        )} */}
        <img
          src={principalPhoto || photoDefault}
          alt="profile"
          loading="lazy"
        />
        <div className="profile-title">
          <div className="user-info">
            <img
              className="profile-img"
              src={principalPhoto || photoDefault}
              alt="profile"
              loading="lazy"
            />
            <div className="user-title">
              <p className="profile-info no-margin">{name}</p>
              {isVip && <span className="icon icon-burn icon-md"></span>}
            </div>
            <p className="counter">{age} years</p>
            {/* <Rating rating={rating} isVip={isVip} /> */}
            <div className="profile-statistics">
              <div className="statistics-info">
                <span className="icon icon-video icon-sm icon-gray"></span>
                <p className="counter">5</p>
              </div>

              <div className="statistics-info">
                <span className="icon icon-photo icon-sm icon-gray"></span>
                <p className="counter">237</p>
              </div>

              <div className="statistics-info">
                <span className="icon icon-heart-solid icon-sm icon-gray"></span>
                <p className="counter">12.3k</p>
              </div>
            </div>
          </div>
          <div className="profile-banner-buttons">
            <Button buttonStyle="quintenary" onClick={sendMessageClick}>
              SEND MESSAGE
            </Button>
            <ButtonIcon
              onClick={heartButtonClick}
              buttonStyle={buttonStyle}
              icon={icon}
            />
          </div>
        </div>
      </div>
      <div className="profile-bio">
        <label>About Me</label>
        <p className="small-p no-margin">
          Hey, gorgeous! 🌹 I’m Scarlet, your favorite content creator, model,
          and social media influencer.
        </p>
        {/* <div className="category-wrapper">
          {userInfo.map((info, index) => (
            <Category key={index} children={info} />
          ))}
        </div> */}
        <label>Essentials</label>

        <div className="essentials-list">
          {essentialCards.map((card, index) => (
            <div className="essential-card" key={index}>
              <span
                className={`icon ${card.icon} icon-md icon-gray icon-round`}
              ></span>
              <div className="essential-wrapper">
                <label className="essential-label">{card.gender}</label>
                <p className="essential-info no-margin">{card.info}</p>
              </div>
            </div>
          ))}
        </div>

        {/* <div className="category-wrapper">
          {userLanguage.map((info, index) => (
            <Category key={index} children={info} />
          ))}
        </div> */}
        <label>Interests</label>
        <div className="category-wrapper">
          {userInterests.map((info, index) => (
            <Category key={index} children={info} />
          ))}
        </div>
        {/* <div className="photo-wrapper">
          <img src={photo1} />
          <img src={photo2} />
        </div> */}
        <label>Social</label>
        <div className="social-buttons">
          <Button buttonStyle="iconButton">
            <img src={twitterIcon} />
          </Button>
          <Button buttonStyle="iconButton">
            <span className="icon icon-tiktok icon-md icon-gray"></span>
          </Button>
          <Button buttonStyle="iconButton">
            <span className="icon icon-instagram icon-md icon-gray"></span>
          </Button>
          <Button buttonStyle="iconButton">
            <span className="icon icon-link icon-md icon-gray"></span>
          </Button>
        </div>
        {/* <ProfileDropDown />
        <ProfileDropDown />
        <div className="photo-wrapper">
          <img src={photo3} />
          <img src={photo4} />
          <img src={photo5} />
          <img src={photo6} />
          <img src={photo7} />
          <img src={photo8} />
        </div> */}
      </div>
      <div className="profile-disclaimer">
        <span className="icon icon-messages icon-md icon-gray"></span>
        <p className="profile-info small no-margin margin-top-8">SAY HELLO!</p>
        <p className="small-p no-margin">
          Send me a message to see all my exclusive content
        </p>
      </div>

      <Button buttonStyle="primary" onClick={sendMessageClick}>
        Send Message
      </Button>
    </div>
  );
};

export default NewProfile;

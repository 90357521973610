export const dollarFormat = (value: string) => {
  let cleanValue = value.replace(/[^0-9.]/g, "");

  if (!cleanValue || isNaN(parseFloat(cleanValue))) {
    return "";
  }

  const numericValue = parseFloat(cleanValue);

  const formattedValue = numericValue.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedValue;
};
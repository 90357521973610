export const startInterval = (
  setTimer: (time: string) => void
): NodeJS.Timeout => {
  let seconds = 0;
  setTimer("0:00");

  const interval = setInterval(() => {
    seconds++;

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = `${String(minutes)}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;

    setTimer(formattedTime);
  }, 1000);

  return interval;
};

export const stopInterval = (
  intervalId: NodeJS.Timeout,
  setTimer: (time: string) => void
) => {
  clearInterval(intervalId);
  setTimer("0:00");
};

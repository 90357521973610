import React, { useState } from "react";
import "./styles.scss";

interface PhotoGalleryProps {
  // files: File[] | [];
  files: string[];
}

const PhotoGallery: React.FC<PhotoGalleryProps> = (props) => {
  const { files } = props;
  return (
    <div id="photo-gallery">
      {files.map((file, index) => (
        <img
          key={index}
          // src={URL.createObjectURL(file)}
          src={file}
          alt={`preview ${index}`}
        />
      ))}
    </div>
  );
};

export default PhotoGallery;

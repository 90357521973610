import fetchApi from "../fetchApi";

const UpdatePassword = async (data: { token: string; newPassword: string }) => {
  return await fetchApi({
    service: "auth",
    endPoint: "/update-password",
    method: "POST",
    data,
  });
};

export default UpdatePassword;

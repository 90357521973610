import React, { useState } from "react";
import "./styles.scss";

import Button from "../../Buttons/Button";
import InputText from "../../Inputs/InputText";
import ListItemSecondary from "../../Lists/ListItemSecondary";
import SubText from "../../Texts/SubText";
import DropDown from "../../Lists/DropDown";
import infoIcon from "../../../assets/icons/union-gray.svg";
import rosesIcon from "../../../assets/icons/roses-default.svg";
import rosesGrayIcon from "../../../assets/icons/roses-gray.svg";

interface AutoRechargeProps {
  cards: {
    banner: string;
    cardType: "Debit" | "Credit";
    number: string;
  }[];
  applyRechargeCallback: (a?: any) => void;
}

const AutoRecharge: React.FC<AutoRechargeProps> = (props) => {
  const { cards, applyRechargeCallback } = props;
  const [selectedCard, setSelectedCard] = useState("");

  const applyRechargeHandler = () => {
    if (selectedCard) {
      applyRechargeCallback(selectedCard);
    }
    applyRechargeCallback();
  };

  const [autoRecharge, setAutoRechargeValue] = useState<number>(0);
  const [toggle, setToggle] = useState<boolean>(false);

  return (
    <div className="auto-recharge margin-top-16 padding-24 padding-top-8">
      <ListItemSecondary
        showLabel={true}
        labelContent="Auto Recharge"
        showDescription={true}
        descriptionContent="Automatically recharge rose credits."
        showToggle={true}
        onToggleChange={setToggle}
        className="auto-recharge-list-item"
      />

      <div className="auto-recharge__main" data-disabled={toggle}>
        <div className="auto-recharge-input-container margin-top-16 margin-bottom-4">
          <InputText
            label={
              <>
                Auto-recharge <span className="tip">0.75¢ per Roses</span>
              </>
            }
            value={String(autoRecharge)}
            disabled={!toggle}
            placeholder="75"
            inputType="number"
            iconLeft={toggle ? rosesIcon : rosesGrayIcon}
            onChangeInput={(amount) => {
              setAutoRechargeValue(Number(amount) < 0 ? 0 : Number(amount));
            }}
          />

          <DropDown
            placeholder="Select card"
            textButton={"Manage Payment Methods"}
            dropType={"radio"}
            options={
              cards
                ? cards.map((item) => {
                    return {
                      groupName: "cards",
                      inputLabel: `${item.cardType} ${item.number.slice(-4)}`,
                      inputValue: `${
                        item.cardType
                      } Card ****${item.number.slice(-4)}`,
                    };
                  })
                : []
            }
            setValueCallBack={function (option: string[]): void {}}
          />
        </div>
        <SubText icon={infoIcon} className={"auto-recharge-sub-text"}>
          Only payment methods available for auto recharge show here. Minimum of
          25 Roses.
        </SubText>

        <Button
          disabled={!toggle}
          buttonStyle="quintenary"
          onClick={applyRechargeHandler}
          className="margin-top-16"
        >
          Apply Auto-Recharge
        </Button>
      </div>
    </div>
  );
};

export default AutoRecharge;

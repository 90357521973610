import fetchApi from "../fetchApi";

export type MomentBodyRequest = {
  price: number;
  file: File;
};

const postMoment = async (token: string, data: MomentBodyRequest) => {
  return await fetchApi({
    service: "moments",
    method: "POST",
    endPoint: ``,
    data,
    token,
  });
};

export default postMoment;

import React from "react";
import "./styles.scss";
import { MomentsDetails } from "../../types/moments";

interface MomentsProps {
  clickMoment: (userId: string) => void;
  className?: string;
  momentsImages: MomentsDetails[];
}

const Moments: React.FC<MomentsProps> = (props) => {
  const { clickMoment, className, momentsImages } = props;
	
  return (
    <div id="moments-component" className={`padding-vert-16 ${className}`}>
      {momentsImages.map((moment, index) => (
        <div
          key={index}
          className={`moment-wrapper ${
            moment.wasSeen ? "new-moment-available" : "moment-was-seen"
          }`}
          onClick={() => clickMoment(moment.userId)}
        >
          <img
            className="moment-image padding-4"
            src={moment.img}
            alt="moment"
          />
        </div>
      ))}
    </div>
  );
};

export default Moments;

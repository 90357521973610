const cards: {
  banner: string;
  cardType: "Debit" | "Credit";
  number: string;
  default: boolean;
}[] = [
  {
    banner: "Visa",
    cardType: "Debit",
    number: "1234-5678-9012-3456",
    default: false,
  },
  {
    banner: "MasterCard",
    cardType: "Credit",
    number: "9876-5432-1098-7654",
    default: false,
  },
  {
    banner: "American Express",
    cardType: "Credit",
    number: "3714-496353-98431",
    default: true,
  },
  {
    banner: "Discover",
    cardType: "Debit",
    number: "6011-1111-1111-1117",
    default: false,
  },
];

export default cards;

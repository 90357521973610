import { useEffect, useState } from "react";
import Header from "../../components/Headers/Header";

import "./styles.scss";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import ListItem from "../../components/Lists/ListItem";
import CheckIconGreen from "../../assets/icons/check-simple-green.svg";
import Toast from "../../components/Toast";
import { useUserInfo } from "../../hooks/userInfo";
import getSelfReferralCode from "../../api/creatorApplications/getSelfReferralCode";
import getReferralList from "../../api/creatorApplications/getReferralList";
import postAddReferralCode from "../../api/creatorApplications/postAddReferralCode";
import { IToast } from "../../types/toast";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";

const AddReferralsPage: React.FC = () => {
  const [referral, setReferral] = useState("");
  const [myReferralCode, setMyReferralCode] = useState("");
  const [referralList, setReferralList] = useState([] as any[]);
  const [isReferralInputWarning, setIsReferralInputWarning] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });

  const { userInfo } = useUserInfo();
  const { setPrincipalScreen } = useDeskNavigation();

  useEffect(() => {
    const fetchReferralCode = async () => {
      const response = await getSelfReferralCode(userInfo.access_token);
      setMyReferralCode(response?.res.referralCode);
    };

    fetchReferralCode();
    fetchReferralList();
  }, []);

  const fetchReferralList = async () => {
    const response = await getReferralList(userInfo.access_token);
    setReferralList(response?.res.data);
  };

  const useCopyCode = () => {
    navigator.clipboard.writeText(myReferralCode);

    setToastConfig({
      title: "Copy",
      type: "success",
      description: "Code copied to clipboard.",
    });

    setToastShow(true);

    setTimeout(() => {
      setToastShow(false);
    }, 3000);
  };

  const useAddReferral = async () => {
    const response = await postAddReferralCode(userInfo.access_token, referral);
    switch (response?.status) {
      case 200:
        setToastConfig({
          title: "Referral Added",
          type: "success",
          description: "Referral added with success!",
        });

        setToastShow(true);

        setTimeout(() => {
          setToastShow(false);
        }, 3000);

        break;

      default:
        setToastConfig({
          title: "Something went wrong!",
          type: "error",
          description: "Please, check th referral code and try again",
        });

        setToastShow(true);
        setIsReferralInputWarning(true);

        setTimeout(() => {
          setToastShow(false);
          setIsReferralInputWarning(false);
        }, 4000);
        break;
    }
  };

  return (
    <div className="add-referrals">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={() => setPrincipalScreen("/wait-list-application")}
          title="Add referrals"
          subTitle={
            <>
              Enter a referral code from a shared link or invite friends to join
              Roses using your referral code:{" "}
              <span className="code-text">{myReferralCode}</span>{" "}
            </>
          }
        />
        <InputText
          value={referral}
          placeholder="Enter referral code"
          onChangeInput={(e) => setReferral(e)}
          isWarning={isReferralInputWarning}
          inputType="text"
        />

        {referralList?.map((referral, index, arr) => (
          <ListItem
            key={index}
            showLabel={true}
            labelContent={referral.displayName}
            showValue
            valueContent={referral.referralCode}
            showIconLeft
            iconLeftContent={CheckIconGreen}
            showSeparator={arr.length > index + 1}
          />
        ))}
      </div>

      <Button buttonStyle="primary" onClick={useAddReferral}>
        Add Referral
      </Button>
      <Button buttonStyle="tertiary" onClick={useCopyCode}>
        Copy Code
      </Button>

      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default AddReferralsPage;

import "./styles.scss";

interface IRoseCredit {
  text: string;
  buttonStyle?:
  | "gold"
  className?: string;
}

const RosesCredit: React.FC<IRoseCredit> = (props) => {
  const { text, buttonStyle = 'default', className } = props
  return (
    <button className={`roses-credit roses-credit--${buttonStyle} ${className ?? ''}`}>
      <span className="icon icon-md icon-roses-outline"></span>
      {text}
    </button>
  )
}

export default RosesCredit;

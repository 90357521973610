import React, { useEffect, useState } from "react";
import "./styles.scss";
import Button from "../../Buttons/Button";
import rosesLogoIconPink from "../../../assets/icons/logos/logo-rose-icon-pink.svg";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../hooks/languageContext";
import { useLoginSignUpNavigation } from "../../../hooks/useLoginSignUpNavigation";
import AccountLoginPage from "../../../pages/AccountLogin";
import TranslatableText from "../../Texts/TranslatableText";

const NavBar: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();

  const { setComponent } = useLoginSignUpNavigation();

  const handleChangeLanguage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLanguage(event.target.value as any);
  };

  const handleOpenApp = () => {
    setComponent(<AccountLoginPage />);
    navigate("/login");
  };

  const handleTriggerClick = () => {
    const navWrapper = document.querySelector("#nav-bar-landing-page");
    if (navWrapper) {
      navWrapper.classList.toggle("open");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div id="nav-bar-landing-page" className={isScrolled ? "scrolled" : ""}>
      <div
        className="logo-container"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={rosesLogoIconPink} alt="Roses Logo" className="padding-4" />
        <h1>
          Roses<sup>™</sup>
        </h1>
      </div>
      <div className="button-container-nav-bar">
        <ul className="nav no-margin no-padding">
          <li>
            <a href="/#content-why-us-wrapper" textkey="1/navigation-01">
              Why Us
            </a>
          </li>
          <li>
            <a href="/#features" textkey="1/navigation-02">
              Features
            </a>
          </li>

          <select
            id="language-code"
            value={language}
            onChange={handleChangeLanguage}
          >
            <option value="ar">🇸🇦&nbsp;&nbsp;العربية</option>
            <option value="de">🇩🇪&nbsp;&nbsp;Deutsch</option>
            <option value="en">🇬🇧&nbsp;&nbsp;English</option>
            <option value="es-ES">🇪🇸&nbsp;&nbsp;Español</option>
            <option value="fr">🇫🇷&nbsp;&nbsp;Français</option>
            <option value="hi">🇮🇳&nbsp;&nbsp;हिन्दी</option>
            <option value="it">🇮🇹&nbsp;&nbsp;Italiano</option>
            <option value="ja">🇯🇵&nbsp;&nbsp;日本語</option>
            <option value="ko">🇰🇷&nbsp;&nbsp;한국어</option>
            <option value="pt-BR">🇧🇷&nbsp;&nbsp;Português (Br)</option>
            <option value="pt-PT">🇵🇹&nbsp;&nbsp;Português (Pt)</option>
            <option value="ro">🇷🇴&nbsp;&nbsp;Română</option>
            <option value="ru">🇷🇺&nbsp;&nbsp;Русский</option>
            <option value="uk">🇺🇦&nbsp;&nbsp;Українська</option>
            <option value="zh-CN">🇨🇳&nbsp;&nbsp;中文 (简体)</option>
            <option value="zh-TW">🇹🇼&nbsp;&nbsp;中文 (繁體)</option>
          </select>
        </ul>
        {/* <Button
          buttonStyle="primary"
          children={
            <TranslatableText
              textAlt
              fontSize="10px"
              textKey="1/navigationButton"
            />
          }
          onClick={handleOpenApp}
        /> */}
      </div>
      <div className="trigger margin-top-8" onClick={handleTriggerClick}></div>
    </div>
  );
};

export default NavBar;

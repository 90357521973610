export const base64OrBlobToFile = async (
  url: string,
  filename: string
): Promise<File | null> => {
  if (url.startsWith("blob:")) {
    const response = await fetch(url);
    const blob = await response.blob();

    if (blob.type.startsWith("image/") || blob.type.startsWith("video/")) {
      return new File([blob], filename, { type: blob.type });
    }
    return null;
  }

  const matches = url.match(/^data:(.+);base64,(.*)$/);
  if (matches) {
    const mimeType = matches[1];
    const byteCharacters = atob(matches[2]);
    const byteNumbers = Array.from(byteCharacters, (char) =>
      char.charCodeAt(0)
    );
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    if (mimeType.startsWith("image/") || mimeType.startsWith("video/")) {
      return new File([blob], filename, { type: mimeType });
    }
    return null;
  }

  return null;
};

export const handleUploadMedia = async (urls: string[]): Promise<File[]> => {
  const filePromises = urls.map((url, index) => {
    const filename = url.startsWith("data:") ? `media_${index}` : "video.mp4";
    return base64OrBlobToFile(url, filename);
  });
  const files = await Promise.all(filePromises);
  return files.filter((file): file is File => file !== null);
};

import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import Button from "../../components/Buttons/Button";
import ButtonNavigation from "../../components/Buttons/ButtonNavigation";
import InputText from "../../components/Inputs/InputText";
import PageTitle from "../../components/PageTitles";
import AutoRecharge from "../../components/Wallet/AutoRecharge";
import RosesDashboard from "../../components/Wallet/RosesDashboard";
import Toast from "../../components/Toast";
import SendRosesModal from "../../components/Chat/ChatModals/SendRosesModal";
import ListItemExtract from "../../components/Lists/ListItemExtract";
import SlidUp from "../../components/Modals/SlidUp";
import bankIcon from "../../assets/icons/bank.icon-gray.svg";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import getUserSelfDetail from "../../api/getUserSelfDetail";
import postBuyRoses from "../../api/rosesTransactions/postBuyRoses";
import postWithdrawRoses from "../../api/rosesTransactions/postWithdrawRoses";
import getAutoRecharge from "../../api/rosesTransactions/autoRecharge/getAutoRecharge";
import getExtract from "../../api/extract/getExtract";
import { IToast } from "../../types/toast";
import { formatDateShort } from "../../utils/formatDateShort";
import { thousandsFormat } from "../../utils/thousandsFormat";
import { textCapitalize } from "../../utils/textCapitalize";
import { dollarFormat } from "../../utils/dollarFormat";

import cardsMock from "../../mocks/cards";
import { useModal } from "../../hooks/useModal";
import ListItemRadio from "../../components/Lists/ListItemRadio";
import { result } from "lodash";

type IInvoice = {
  type: string;
  createdAt: string;
  amount: number;
  cash: string;
};

const UserSettingsWalletPage: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>("button1");
  const [cardDefault, setCardDefault] = useState("");
  const [userRosesAvailable, setUserRosesAvailable] = useState(0);
  const [invoices, setInvoices] = useState<IInvoice[]>();
  const [search, setSearch] = useState("");
  const [toastShow, setToastShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const cards = cardsMock;
  const listExtractsRef = useRef<(HTMLDivElement | null)[]>([]);
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo } = useUserInfo();

  useEffect(() => {
    getSelfDetail();
    handleGetExtract();
  }, []);

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  const applyRechargeRequest = async () => {
    const request = await getAutoRecharge(userInfo.access_token);
    return request;
  };

  const getSelfDetail = async () => {
    const request = await getUserSelfDetail(userInfo.access_token);
    if (request.status === 200) {
      setUserRosesAvailable(request.res.quantityRoses);
    }
  };

  const handleBuyRoses = async () => {
    const packageId = "b6f8bf93-fe69-4327-bc31-d35d5a762a20";
    const request = await postBuyRoses(userInfo.access_token, packageId);

    if (request.status === 201) {
      const { res } = await getUserSelfDetail(userInfo.access_token);
      setUserRosesAvailable(res.quantityRoses);
      handleToast({
        type: "success",
        title: "Buy Roses",
        description: "Congratulations",
      });
    } else {
      handleToast({
        type: "error",
        title: "Buy Roses",
        description: "Congratulations",
      });
    }
  };

  const handleRedeemRoses = async () => {
    const request = await postWithdrawRoses(
      userInfo.access_token,
      userRosesAvailable
    );
    if (request.status === 201) {
      const { res } = await getUserSelfDetail(userInfo.access_token);
      setUserRosesAvailable(res.quantityRoses);
      handleToast({
        type: "success",
        title: "Redeem Roses",
        description: "Congratulations",
      });
    } else if (request.status === 422) {
      handleToast({
        type: "info",
        title: "Redeem Roses",
        description: "Not has roses to redeem",
      });
    } else {
      handleToast({
        type: "error",
        title: "Redeem  Roses",
        description: "Error",
      });
    }
  };

  const handleGetExtract = async () => {
    const dateEnd = new Date().toISOString();
    const dateStart = new Date("2020-01-01").toISOString();
    const request = await getExtract(
      userInfo.access_token,
      userInfo.user_id,
      dateStart,
      dateEnd
    );

    if (request.status === 200) {
      setInvoices(request.res);
    }
  };

  const handleToast = ({ type, title, description }: IToast) => {
    setToastShow(true);
    setToastConfig({
      type,
      title,
      description,
    });
    setTimeout(() => {
      setToastShow(false);
    }, 3000);
  };

  const mapMonths = {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
  };

  const getTextContent = (parent: HTMLDivElement, selector: string) =>
    parent.querySelector(selector)?.textContent?.toLocaleLowerCase() || "";

  const handlerSearchFilter = (text: string) => {
    const formattedSearch = text
      .toString()
      .toLocaleLowerCase()
      .trim()
      .split(/\s+/);

    listExtractsRef.current.forEach((item) => {
      if (!item) return;

      const typeTransaction = getTextContent(item, "[data-title]");
      const dataTransaction = getTextContent(item, "[data-date]");
      const amountTransaction = getTextContent(item, "[data-amount]");
      const valueTransaction = getTextContent(item, "[data-value]");

      const dataShort = dataTransaction ? dataTransaction.slice(0, 3) : "";
      const dataLong = mapMonths[dataShort as keyof typeof mapMonths] || "";

      const completeTransaction = `${typeTransaction} ${amountTransaction} ${dataTransaction}${dataLong} ${valueTransaction}`;

      const match = formattedSearch.every((term) =>
        completeTransaction.includes(term)
      );

      item.classList.toggle("show", match);
      item.classList.toggle("hidden", !match);
    });
  };

  console.log(cardDefault);
  // const { openUseModal, setOpenUseModal } = useModal();

  return (
    <div className="user-settings-wallet">
      <PageTitle
        title={"My Wallet"}
        subtitle={"Manage Payment"}
        onBackClick={() => setPrincipalScreen("/user-settings")}
        hasCloseIcon={false}
        className="padding-hor-24"
      />
      <ButtonNavigation
        setActiveSection={handleSectionChange}
        activeSection={activeSection}
        buttonCount={3}
        buttonLabels={["Overview", "Activity", "Payout"]}
        className="margin-bottom-24 padding-hor-24"
      />

      {activeSection === "button1" && (
        <section className="available-roses-recharge margin-hor-24">
          <RosesDashboard
            title={thousandsFormat(userRosesAvailable)}
            showAddButton={true}
            showGoldRoses={true}
            onClick={() => setIsOpen(!isOpen)}
          />

          <AutoRecharge
            cards={cards}
            applyRechargeCallback={applyRechargeRequest}
          />
          <SlidUp isOpen={isOpen}>
            <SendRosesModal
              title="Buy Roses"
              description="Select Roses below"
              showRosesOptions
              showInfoGold
              showCards
              tableData={[
                {
                  type: 'gold',
                  label: 'Golden Roses',
                  values: [4],
                },
                {
                  type: 'data',
                  label: 'Roses',
                  values: [150, '$485.00'],
                },
                {
                  type: 'total',
                  label: 'Total',
                  values: [154, '$485.00'],
                },
              ]}
              typeModal="buy"
              buttonText="Buy Roses"
              maxValue={10000}
              onClose={() => setIsOpen(false)}
              onClick={() => handleBuyRoses()}
            />
          </SlidUp>
        </section>
      )}

      {activeSection === "button2" && (
        <section className="transactions-activity margin-hor-24">
          <InputText
            value={search}
            placeholder="Search"
            onChangeInput={(text) => {
              setSearch(text);
              handlerSearchFilter(text);
            }}
            searchInput
            inputType="text"
          />
          <div className="list-container margin-top-16">
            {invoices &&
              invoices.map((invoice, index) => {
                return (
                  <ListItemExtract
                    ref={(el) => (listExtractsRef.current[index] = el)}
                    key={index}
                    title={textCapitalize(invoice.type)}
                    description={formatDateShort(invoice.createdAt)}
                    date={{
                      long: invoice.createdAt,
                      short: formatDateShort(invoice.createdAt),
                    }}
                    amount={thousandsFormat(invoice.amount)}
                    value={dollarFormat(String(invoice.cash ?? "0"))}
                    isExtract
                    iconRight={chevronRightIcon}
                  />
                );
              })}
          </div>
        </section>
      )}

      {activeSection === "button3" && (
        <section className="redeem-credits padding-hor-24">
          <RosesDashboard
            title={thousandsFormat(userRosesAvailable)}
            showRedeemButton={true}
            onClick={handleRedeemRoses}
          />
          <p className="redeem-credits__header margin-top-16 margin-bottom-4">
            Bank account
          </p>
          <div className="list-container">
            {cards.map((card, index, arr) => (
              // <ListItemSecondary
              //   key={card.number}
              //   showIconLeft={true}
              //   iconLeftContent={bankIcon}
              //   showIconBorderLeft={true}
              //   showLabel={true}
              //   labelContent={card.banner}
              //   showDescription={true}
              //   descriptionContent={card.number}
              //   showRadioRight={true}
              //   radioRightGroupName="card-default"
              //   radioRightValue={card.number}
              //   showValue={card.default}
              //   valueContent={"Default"}
              //   onChangeRightRadioCallback={setCardDefault}
              //   showSeparator={arr.length > index + 1}
              //   className={"list-container-list-item"}
              // />

              <ListItemRadio
                key={index}
                groupName="card-default"
                radioId={index + ""}
                imageLeft={bankIcon}
                hasSeparator={arr.length > index + 1}
                highlightText={card.banner}
                simpleText={card.number}
                checkValueCallback={setCardDefault}
                isImageCircle
                inputValue={card.number}
                isChecked={card.default}
                rightNodeContent={
                  card.default && <p className="text-detail">Default</p>
                }
              />
            ))}
          </div>
          <Button
            buttonStyle="tertiary"
            onClick={() => { }}
            children={"Connect Account"}
            className="margin-top-16"
          />
        </section>
      )}

      <Toast
        type={toastConfig.type}
        title={toastConfig.title}
        description={toastConfig.description}
        isVisible={toastShow}
        setIsVisible={setToastShow}
      />
    </div>
  );
};

export default UserSettingsWalletPage;

import fetchApi from "./fetchApi";

const getUserSelfDetail = async (token: string) => {
  return await fetchApi({
    service: "user",
    endPoint: `/self-detail`,
    method: "GET",
    token,
  });
};

export default getUserSelfDetail;

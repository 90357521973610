import React from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import ListItem from "../../components/Lists/ListItem";
import ClockIconGray from "../../assets/icons/clock-icon-gray.svg";
import PrivacyIconGray from "../../assets/icons/privacy-icon-gray.svg";
import Button from "../../components/Buttons/Button";
import { useUserInfo } from "../../hooks/userInfo";
import DeleteMembershipUserApi from "../../api/deleteMembershipApplicationAdmin";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useNavigate } from "react-router-dom";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import ListItemToggle from "../../components/Lists/ListItemToggle";

const UserSettingsManageAccountPage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const { setComponent } = useLoginSignUpNavigation();
  const navigate = useNavigate();
  const { setPrincipalScreen, setSecondaryScreen, setTertiaryScreen } =
    useDeskNavigation();

  const takeABreakHandler = (event: boolean) => {};
  const blockRequestsHandler = () => {};
  const deleteAccountHandler = async () => {
    let phone = userInfo.user_phone.replace("+", "");

    if (
      phone === "5511967700452" ||
      phone === "14074464664" ||
      phone === "13053704780" ||
      phone === "5545999640276" ||
      phone === "5511914013654" ||
      phone === "5519984484218"
    ) {
      try {
        const deleteResponse = await DeleteMembershipUserApi(phone);
        if (deleteResponse.status === 204) {
          localStorage.clear();
          sessionStorage.clear();
          setPrincipalScreen("");
          setSecondaryScreen("");
          setTertiaryScreen("");
          setComponent("");
          navigate("/");
        } else {
          console.error(
            "Unexpected response from delete operation",
            deleteResponse
          );
        }
      } catch (error) {
        console.error("There was an error during the delete operation:", error);
      }
    }
  };

  return (
    <div className="user-settings-manage-account ">
      <PageTitle
        title={"Manage Account"}
        subtitle={"Account Security"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/user-settings-account")}
        className="padding-hor-24"
      />

      <div className="list-container-settings margin-hor-24">
        <ListItemToggle
          toggleInitialState
          hasSeparator
          highlightText="Account"
          simpleText="Deactivate my account for 7 days"
          isImageCircle
          imageLeft={ClockIconGray}
          toggleCallBack={takeABreakHandler}
        />

        <ListItemToggle
          toggleInitialState
          highlightText="Block Requests"
          simpleText="Prohibit rose requests"
          isImageCircle
          imageLeft={PrivacyIconGray}
          toggleCallBack={blockRequestsHandler}
        />
        <Button buttonStyle="quaternary" onClick={deleteAccountHandler}>
          Delete Account
        </Button>
      </div>
    </div>
  );
};

export default UserSettingsManageAccountPage;

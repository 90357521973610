import React, { useEffect, useState } from "react";
import "./styles.scss";

interface InputCheckboxProps {
  elements: InputCheckboxElement[];
  onChangeValueCallback: (a: string[]) => void;
  className: string;
  defaultValueProp: string[];
}
interface InputCheckboxElement {
  inputLabel: string;
  inputValue: string;
  groupName: string;
}

const InputCheckbox: React.FC<InputCheckboxProps> = (props) => {
  const { elements, onChangeValueCallback, className, defaultValueProp } =
    props;
  const [inputValues, setInputsValues] = useState<string[]>(defaultValueProp);
  const checkValueCallback = (
    event: React.ChangeEvent<HTMLInputElement>,
    element: string
  ) => {
    let updatedValues: string[];

    if (event.target.checked) {
      updatedValues = [...defaultValueProp, element];
    } else {
      updatedValues = defaultValueProp.filter((value) => value !== element);
    }
    setInputsValues(updatedValues);
    onChangeValueCallback(updatedValues);
  };

  return (
    <div id="input-checkbox-default" className={className ?? ""}>
      {elements.map((element, index) => (
        <div className="label-input-container padding-16" key={index}>
          <label
            htmlFor={`${element.inputLabel}-${index}`}
            className="container-checkbox no-margin"
          >
            {element.inputValue}
            <input
              id={`${element.inputLabel}-${index}`}
              name={element.groupName}
              value={element.inputValue}
              type="checkbox"
              onChange={(event) => {
                checkValueCallback(event, element.inputValue);
              }}
              checked={defaultValueProp?.includes(element.inputValue)}
            />
            <span className="container-checkbox__checkmark"></span>
          </label>

          {index + 1 < elements.length && <div className="separator" />}
        </div>
      ))}
    </div>
  );
};

export default InputCheckbox;

import fetchApi from "../fetchApi";

const createUser = async (data: {
  displayname: string;
  email: string;
  password: string;
  phone?: string;
  gender?: string;
  preferedGenders?: string[];
}) => {
  return await fetchApi({
    service: "auth",
    endPoint: "/register",
    method: "POST",
    data,
  });
};

export default createUser;

import fetchApi from "../../fetchApi";

const getAutoRecharge = async (token: string) => {
  return await fetchApi({
    service: "autoRecharge",
    method: "GET",
    token,
  });
};

export default getAutoRecharge;

import { servicesApi } from "../fetchApi/services-api";

interface BodyDataRequest {
  place?: string;
  region?: string;
  country?: string;
  country_code?: string;
  country_code_alpha_3?: string;
}

const getUserLocationHistory = async (token: string) => {
  const apiRoute = `${servicesApi("user")}/location-history`;

  try {
    const response = await fetch(apiRoute, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const res = await response.json();
    return { res, status: response.status };
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return { res: null, status: null };
  }
};

export default getUserLocationHistory;

import React from "react";
import "./styles.scss";
import Button from "../../Buttons/Button";

interface TextActionCardProps {
  className?: string;
  buttonClick: (a: any) => void;
  principalText: string;
  secondaryText: string;
  textButton: string;
}

const TextActionCard: React.FC<TextActionCardProps> = (props) => {
  const { className, buttonClick, principalText, secondaryText, textButton } =
    props;

  return (
    <div id="text-action-card" className={className ?? ""}>
      <h6 className="no-margin principal-text">{principalText}</h6>
      <p className="small-p margin-bottom">{secondaryText}</p>
      <Button buttonStyle="secondary" onClick={buttonClick}>
        {textButton}
      </Button>
    </div>
  );
};

export default TextActionCard;

import { servicesApi } from "../fetchApi/services-api";

interface BodyDataRequest {
  gender?: string;
  birthDate?: Date;
  height?: number;
  weight?: number;
  maritalStatus?: string;
  occupation?: string;
  netWorth?: string;
  profileEyeColor?: string;
  profileHairColor?: string;
  profileNationality?: string;
  profileEthnicity?: string;
  profileLanguages?: string[];
}

const patchUserInfo = async (token: string, data: BodyDataRequest) => {
  const apiRoute = `${servicesApi("user")}/profile`;

  try {
    const response = await fetch(apiRoute, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const res = await response.json();
    return { res: res, status: response.status };
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export default patchUserInfo;

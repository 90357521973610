const stories = [
  {
    url: require("../assets/images/membership/step-two.png"),
    isVip: true,
    name: "Scarlet",
    age: 24,
    distance: "3 km",
    rating: 4,
    isVerified: true,
  },
  {
    url: require("../assets/images/membership/step-six.png"),
    isVip: true,
    name: "Scarlet",
    age: 24,
    distance: "3 km",
    rating: 4,
    isVerified: true,
  },
  {
    url: require("../assets/images/membership/pre-step.png"),
    isVip: false,
    name: "Scarlet",
    age: 24,
    distance: "3 km",
    rating: 4,
    isVerified: true,
  },
  {
    url: require("../assets/images/membership/step-four.png"),
    isVip: true,
    name: "Scarlet",
    age: 24,
    distance: "3 km",
    rating: 4,
    isVerified: false,
  },
  {
    url: require("../assets/images/landingPageImages/roses-auto-translate.png"),
    isVip: true,
    name: "Scarlet",
    age: 24,
    distance: "3 km",
    rating: 4,
    isVerified: true,
  },
  {
    url: require("../assets/images/landingPageImages/roses-autotranslate-chat-female.png"),
    isVip: false,
    name: "Scarlet",
    age: 24,
    distance: "3 km",
    rating: 4,
    isVerified: false,
  },
  {
    url: require("../assets/images/landingPageImages/roses-pixel-shield-off.png"),
    isVip: false,
    name: "Scarlet",
    age: 24,
    distance: "3 km",
    rating: 4,
    isVerified: false,
  },
  {
    url: require("../assets/images/landingPageImages/roses-banner-female-21.jpg"),
    isVip: false,
    name: "Scarlet",
    age: 24,
    distance: "3 km",
    rating: 4,
    isVerified: false,
  },
  {
    url: require("../assets/images/membership/step-two.png"),
    isVip: false,
    name: "Scarlet",
    age: 24,
    distance: "3 km",
    rating: 4,
    isVerified: false,
  },
  {
    url: require("../assets/images/girl-opened-month.png"),
    isVip: false,
    name: "Scarlet",
    age: 24,
    distance: "3 km",
    rating: 4,
    isVerified: false,
  },
  {
    url: require("../assets/images/membership/step-two.png"),
    isVip: false,
    name: "Scarlet",
    age: 24,
    distance: "3 km",
    rating: 4,
    isVerified: false,
  },
  {
    url: require("../assets/images/membership/step-two.png"),
    isVip: false,
    name: "Scarlet",
    age: 24,
    distance: "3 km",
    rating: 4,
    isVerified: false,
  },
  {
    url: require("../assets/images/membership/step-two.png"),
    isVip: false,
    name: "Scarlet",
    age: 24,
    distance: "3 km",
    rating: 4,
    isVerified: false,
  },
  {
    url: require("../assets/images/membership/step-two.png"),
    isVip: false,
    name: "Scarlet",
    age: 24,
    distance: "3 km",
    rating: 4,
    isVerified: false,
  },
];

export default stories;

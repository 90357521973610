import { useModal } from "../../../../hooks/useModal";
import React from "react";
import rosesLogoPink from "../../../../assets/icons/logos/logo-fill-pink.svg";
import "../chat-modal-global-style.scss";
import Button from "../../../Buttons/Button";
import "./styles.scss";

interface UnlockContentModalProps {
  messageId: string;
  unlockContent: (messageId: string) => Promise<void>;
}

const UnlockContentModal: React.FC<UnlockContentModalProps> = (props) => {
  const { messageId, unlockContent } = props;

  const { setOpenUseModal } = useModal();

  return (
    <div className="unlock-file-modal">
      <div className="unlock-file-modal__wrapper">
        <img src={rosesLogoPink} alt="roses logo icon" />
        <h3>Send Roses</h3>
        <p>
          Capture Your Love's Heart: <br />
          Send <b>Roses</b> Now!
        </p>
        <div className="buttons-container">
          <Button
            buttonStyle="primary"
            onClick={() => unlockContent(messageId)}
          >
            Send Roses
          </Button>
          <Button
            buttonStyle="quaternary"
            onClick={() => setOpenUseModal(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UnlockContentModal;

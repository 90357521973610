import React, { useCallback, useRef, useState } from "react";
import "./styles.scss";
import ArrowLeft from "../../../assets/icons/navigationIcons/arrow-left-gray.svg";
import ArrowRight from "../../../assets/icons/navigationIcons/bx-chevron-right.svg";
import CloseIconPeach from "../../../assets/icons/closeIcons/close-icon-peach.svg";

interface SlideFooterPreviewProps {
  slides: {
    src: string;
    fileType: "video" | "image";
  }[];
  fileMaxWidth?: number;
  loopSlides?: boolean;
  hasArrows?: boolean;
  removeFile?: (index: number) => void;
}

const SlideFooterPreview: React.FC<SlideFooterPreviewProps> = (props) => {
  const {
    slides,
    loopSlides,
    hasArrows = true,
    fileMaxWidth,
    removeFile,
  } = props;
  const startX = useRef<number | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const ulRef = useRef<HTMLUListElement | null>(null); // Ref para a UL

  const goToNextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      loopSlides
        ? (prevIndex + 1) % slides.length
        : Math.min(prevIndex + 1, slides.length - 1)
    );
  }, [slides.length, loopSlides]);

  const goToPreviousSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      loopSlides
        ? prevIndex === 0
          ? slides.length - 1
          : prevIndex - 1
        : Math.max(prevIndex - 1, 0)
    );
  }, [slides.length, loopSlides]);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    startX.current = e.clientX;
  }, []);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (startX.current === null) return;

      const diffX = e.clientX - (startX.current || 0);
      if (diffX > 50) {
        goToPreviousSlide();
        startX.current = null;
      } else if (diffX < -50) {
        goToNextSlide();
        startX.current = null;
      }
    },
    [goToNextSlide, goToPreviousSlide]
  );

  const handleMouseUp = useCallback(() => {
    startX.current = null;
  }, []);

  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    startX.current = e.touches[0].clientX;
  }, []);

  const handleTouchMove = useCallback(
    (e: React.TouchEvent) => {
      if (startX.current === null) return;

      const diffX = e.touches[0].clientX - (startX.current || 0);
      if (diffX > 50) {
        goToPreviousSlide();
        startX.current = null;
      } else if (diffX < -50) {
        goToNextSlide();
        startX.current = null;
      }
    },
    [goToNextSlide, goToPreviousSlide]
  );

  const handleTouchEnd = useCallback(() => {
    startX.current = null;
  }, []);

  return (
    <div className="slider-footer-preview">
      {slides.map((slide, index) => (
        <div
          key={index}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          className={`slider-footer-preview__wrapper ${
            index === currentIndex ? "active" : ""
          }`}
        >
          {slide.fileType === "image" ? (
            <div className={`image-slide`}>
              <img
                src={slide.src}
                className="file-slide-carousel"
                alt={`slide carousel ${index + 1}`}
                style={{ maxWidth: fileMaxWidth }}
              />
            </div>
          ) : (
            <div className={`video-slide`}>
              <video
                controls
                className="file-slide-carousel"
                style={{ maxWidth: fileMaxWidth }}
              >
                <source src={slide.src}></source>
              </video>
            </div>
          )}
          {hasArrows && (
            <button className="slider-button prev" onClick={goToPreviousSlide}>
              <img src={ArrowLeft} alt="arrow left" />
            </button>
          )}
          {hasArrows && (
            <button className="slider-button next" onClick={goToNextSlide}>
              <img src={ArrowRight} alt="arrow right" />
            </button>
          )}
        </div>
      ))}
      <ul ref={ulRef} className="preview-files-ul">
        {slides.map((slide, index) => (
          <li
            key={index}
            className="preview-file-li"
            onClick={() => setCurrentIndex(index)}
          >
            {removeFile && (
              <img
                src={CloseIconPeach}
                alt="exclude file icon"
                onClick={() => removeFile(index)}
              />
            )}
            {slide.fileType === "image" ? (
              <img src={slide.src} alt="preview file" />
            ) : (
              <video className="preview-file">
                <source src={slide.src}></source>
              </video>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SlideFooterPreview;

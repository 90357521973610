import React from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import InputText from "../../components/Inputs/InputText";
import { useUserInfo } from "../../hooks/userInfo";
import SubText from "../../components/Texts/SubText";
import TextActionCard from "../../components/Cards/TextActionCard";
import Button from "../../components/Buttons/Button";

const UserSettingsEditProfilePage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo } = useUserInfo();

  const verifyAccountHandler = () => {};

  const saveEditHandler = () => {};

  return (
    <div className="user-settings-edit-profile">
      <PageTitle
        title={"Edit Profile"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/user-settings-profile")}
      />
      <main className="user-settings-edit-profile__main margin-24">
        <div className="user-settings-edit-profile__input-container">
          <InputText
            label="Name"
            placeholder={userInfo.display_name}
            inputType="text"
          />
          <InputText
            label="Username"
            placeholder={userInfo.legal_name}
            inputType="text"
          />
        </div>
        <SubText>
          In most cases, you’ll be able to change your name back to{" "}
          {userInfo.display_name}.roses for another 14 days.
        </SubText>
        <section className="profile-link-container margin-vert-24">
          <p className="profile-link-container__p no-margin">Profile</p>
          <p className="profile-link-container__p no-margin">
            https://roses.vip/
            <span>{userInfo.display_name.toLocaleLowerCase()}</span>
          </p>
        </section>
        <TextActionCard
          buttonClick={verifyAccountHandler}
          principalText="Identity Verification"
          secondaryText="Let’s make sure nobody is trying to impersonate you."
          textButton="Verify Account"
        />
        <Button
          className="margin-top-40"
          buttonStyle="primary"
          onClick={saveEditHandler}
        >
          Done
        </Button>
      </main>
    </div>
  );
};

export default UserSettingsEditProfilePage;

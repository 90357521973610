import React, { useEffect, useState } from "react";
import "./styles.scss";

interface SelectProps {
  options: OptionProps[];
  defaultValueText: string;
  isWarning?: boolean;
  disabled?: boolean;
  value: string;
  onChange: (option: string) => void;
  className?: string;
}

interface OptionProps {
  label: string;
  value: string;
}

const SimpleSelect: React.FC<SelectProps> = (props) => {
  const {
    options,
    defaultValueText,
    value,
    onChange,
    isWarning,
    disabled,
    className,
  } = props;
  const [waningInput, setWarningInput] = useState(false);

  useEffect(() => {
    setWarningInput(isWarning as boolean);

    setTimeout(() => {
      setWarningInput(false);
    }, 3000);
  }, [isWarning]);

  return (
    <select
      id="simple-select"
      className={`${waningInput ? "isWarning" : ""} ${
        disabled ? "disabled" : ""
      } ${className ?? ""}`}
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    >
      <option value="" label={defaultValueText}></option>
      {options.map((option, index) => (
        <option key={index} id="option-default" value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SimpleSelect;

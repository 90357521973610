import React, { forwardRef } from "react";
import "./styles.scss";

interface InputRadioProps {
  elements: InputRadioElement[];
  onChangeValueCallback: (a: string[]) => void;
  className?: string;
  defaultValueProp?: string | string[];
}

interface InputRadioElement {
  inputLabel: string;
  inputValue: string;
  groupName: string;
}

const InputRadio = forwardRef<HTMLDivElement, InputRadioProps>((props, ref) => {
  const { elements, onChangeValueCallback, className, defaultValueProp } =
    props;

  const checkValueCallback = (element: string) => {
    onChangeValueCallback([element]);
  };

  return (
    <div ref={ref} id="input-radio-default" className={className ?? ""}>
      {elements.map((element, index) => (
        <div className="label-input-container padding-hor-16" key={index}>
          <label
            htmlFor={`${element.inputLabel}-${index}`}
            className="container-checkbox padding-vert-16 no-margin"
          >
            {element.inputValue}
            <input
              readOnly
              id={`${element.inputLabel}-${index}`}
              name={element.groupName}
              value={element.inputValue}
              type="radio"
              onChange={() => checkValueCallback(element.inputValue)}
              defaultChecked={element.inputValue == defaultValueProp}
            />
            <span className="checkmark"></span>
          </label>

          {index + 1 < elements.length && <div className="separator" />}
        </div>
      ))}
    </div>
  );
});

export default InputRadio;

import { useModal } from "../../../../hooks/useModal";
import React, { ReactNode, useRef, useState } from "react";
import rosesLogoPink from "../../../../assets/icons/logos/logo-fill-pink.svg";
import rosesLogoGold from "../../../../assets/icons/logos/logo-fill-gold.svg"
import "../chat-modal-global-style.scss";
import Button from "../../../Buttons/Button";
import "./styles.scss";
import LessIcon from "../../../../assets/icons/less-gray-icon.svg";
import MoreIcon from "../../../../assets/icons/more-icon-gray.svg";
import Range from "../../../Ranges/Range";
import InputMessage from "../../../TextAreas/Textarea";
import DropDown from "../../../Lists/DropDown";
import RosesCredit from "../../../RosesCredit";
import cardsMock from "../../../../mocks/cards";
import SubText from "../../../Texts/SubText";

interface SendRosesModalProps {
  showLogo?: boolean;
  showRosesOptions?: boolean;
  showInfoGold?: boolean;
  showSendGold?: boolean;
  showSendMessage?: boolean;
  showCards?: boolean;
  showSubText?: boolean;
  tableData?: TableRow[];
  title: string;
  description: string | ReactNode;
  typeModal?: "send" | "chat" | "buy";
  buttonText?: string;
  maxValue: number;
  className?: string;
  onClose?: () => void;
  onClick?: () => void;
  rosesMessageCallback?: (roses: number, message: string) => void;
}

interface TableRow {
  type: 'gold' | 'data' | 'total';
  label: string;
  values: (string | number)[];
}

const SendRosesModal: React.FC<SendRosesModalProps> = (props) => {
  const {
    showLogo = false,
    showRosesOptions = false,
    showInfoGold = false,
    showSendGold = false,
    showSendMessage = false,
    showCards = false,
    tableData = null,
    title = "Title text",
    description = "Description text",
    typeModal = "send",
    buttonText = "Send Roses",
    maxValue,
    className,
    onClose,
    onClick,
    rosesMessageCallback } = props;
  const { setOpenUseModal } = useModal();
  const [rosesToSend, setRosesToSend] = useState<string | number>(0);
  const [messageToSend, setMessageToSend] = useState("");
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const startIncrement = () => {
    if (intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        setRosesToSend((prevCount) => Number(prevCount) + 1);
      }, 100);
    }
  };

  const startDecrement = () => {
    if (intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        setRosesToSend((prevCount) => Number(prevCount) - 1);
      }, 100);
    }
  };

  const stopAction = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return (
    <div className={`send-roses-modal ${className ?? ""}`}>
      <div className="send-roses-modal__wrapper">
        {showLogo && <img className="margin-bottom-16" src={rosesLogoPink} alt="roses logo icon" />}
        <h3 className="send-roses-modal__title no-margin">{title}</h3>
        <p className="send-roses-modal__description">{description}</p>

        <section className="range-roses-to-send">
          <div
            className="range-roses-to-send__less"
            onMouseDown={startDecrement}
            onMouseUp={stopAction}
            onMouseLeave={stopAction}
            onClick={() => setRosesToSend((prev) => Math.max(Number(prev) - 1, 0))}
          >
            <img src={LessIcon} alt="less icon" />
          </div>
          <div className="range-roses-to-send__value">
            <p className="value-to-send no-margin margin-bottom-8">{rosesToSend}</p>
            <p className="available-value no-margin">
              {typeModal === "send" && `${maxValue} available`}
              {typeModal === "buy" && (
                <>
                  <span className="range-rose__value--quotation">$0.97</span> per Roses
                </>
              )}
            </p>
          </div>
          <div
            className="range-roses-to-send__more"
            onMouseDown={startIncrement}
            onMouseUp={stopAction}
            onMouseLeave={stopAction}
            onClick={() => setRosesToSend((prev) => Math.min(Number(prev) + 1, maxValue))}
          >
            <img src={MoreIcon} alt="more icon" />
          </div>
          <Range
            max={Number(maxValue)}
            min={0}
            rangeCallback={(e) => setRosesToSend(e)}
            className="range-roses-to-send__range margin-vert-24"
            value={rosesToSend}
          />
        </section>

        <section className="send-roses-modal__main">

          {showRosesOptions &&
            <div className="send-roses-modal__grid">
              <RosesCredit text={"50"} />
              <RosesCredit text={"150"} />
              <RosesCredit text={"250"} />
              <RosesCredit text={"500"} buttonStyle="gold" />
            </div>
          }

          {tableData &&
            <table className="send-roses-modal__table">
              <tbody>
                {tableData.map((row: TableRow, index: number) => (
                  <tr key={index} className={`send-roses-modal__table--${row.type}`}>
                    <th>{row.label}</th>
                    {row.values.map((value, i) => (
                      <td key={i}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          }

          {showInfoGold &&
            <>
              <SubText icon={rosesLogoGold} className={"send-roses-modal__info padding-vert-16"}>
                <h3 className="send-roses-modal__info-title roboto-regular no-margin">Win <span className="send-roses-modal__gold-value">6</span> Golden Roses <span className="icon icon-info send-roses-modal__info-icon"></span></h3>
                <p className="send-roses-modal__info-text no-margin">When you buy 50 more Roses</p>
              </SubText>
            </>
          }

          {showSendMessage &&
            <InputMessage
              value={messageToSend}
              placeholder="Add a message..."
              onChangeInput={(e) => setMessageToSend(e)}
            />
          }

          {showCards &&
            <DropDown
              placeholder="Select card"
              textButton={"Manage Payment Methods"}
              className="send-roses-modal__dropDown"
              dropType={"radio"}
              options={
                cardsMock
                  ? cardsMock.map((card) => {
                    return {
                      groupName: "cards",
                      inputLabel: `${card.cardType} ${card.number.slice(-4)}`,
                      inputValue: `${card.cardType} Card ****${card.number.slice(-4)}`,
                    };
                  })
                  : []
              }
              setValueCallBack={function (option: string[]): void { }}
            />
          }

        </section>

        <div className="buttons-container">
          <Button
            buttonStyle="primary"
            onClick={() => {
              onClick && onClick()
              rosesMessageCallback && rosesMessageCallback(Number(rosesToSend), messageToSend)

              console.log(messageToSend)
            }}
          >
            {buttonText}
          </Button>
          <Button
            buttonStyle="quaternary"
            onClick={
              onClose
              // setOpenUseModal(false)
            }
          >
            Cancel
          </Button>
        </div>
      </div>
    </div >
  );
};

export default SendRosesModal;

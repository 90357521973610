import fetchApi from "../fetchApi";

export type PostBodyRequest = {
  price: number;
  content?: string;
  files: File[];
};

const postPost = async (token: string, data: PostBodyRequest) => {
  return await fetchApi({
    service: "posts",
    method: "POST",
    endPoint: ``,
    data,
    token,
  });
};

export default postPost;

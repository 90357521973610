import React, { ReactNode } from "react";
import "./styles.scss";

interface SubTextProps {
  icon?: string;
  altIcon?: string;
  children: ReactNode;
  checkAnswer?: boolean;
  checkValueCallback?: (a: boolean) => void;
  isChecked?: boolean;
  className?: string;
  fontSize?: string;
  fontFamily?: "Roboto Regular" | "Montserrat Variable";
  lineHeight?: string;
}

const SubText: React.FC<SubTextProps> = (props) => {
  const {
    icon,
    altIcon,
    children,
    checkAnswer,
    checkValueCallback,
    isChecked,
    className,
    fontSize = "12px",
    fontFamily = "Roboto Regular",
    lineHeight = "1.4",
  } = props;

  return (
    <div id="icon-disclaimer" className={className}>
      {icon && <img src={icon} alt={altIcon} className="margin-top-8"/>}
      {checkAnswer && checkValueCallback && (
        <label className="container-checkbox">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => checkValueCallback(e.target.checked)}
          />
          <span className="checkmark"></span>
        </label>
      )}
      <p
        className="text-gray only-margin-bottom"
        style={{ fontSize, fontFamily, lineHeight }}
      >
        {children}
      </p>
    </div>
  );
};

export default SubText;
